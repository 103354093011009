import { FantasyProjection } from './projection'

export enum FantasyPosition {
  QB = 'QB',
  RB = 'RB',
  WR = 'WR',
  TE = 'TE',
  FLEX = 'FL',
  DEF = 'D',
  DST = 'DST',
  K = 'K',
  COV = 'COV',
  SFL = 'SFL',
  ED = 'ED',
  DT = 'DI', // value is DI in Database
  LB = 'LB',
  CB = 'CB',
  S = 'S',
  DL = 'DL',
  DB = 'DB',
}

export enum FantasyDisplayPosition {
  QB = 'QB',
  RB = 'RB',
  WR = 'WR',
  TE = 'TE',
  DEF = 'D',
  DST = 'DST',
  K = 'K',
  BE = 'BE',
  FLEX = 'W/R/T',
  WRRB_FLEX = 'W/R',
  REC_FLEX = 'W/T',
  SUPER_FLEX = 'Q/W/R/T',
}

export enum FantasyTag {
  PFF_VALUE = 'pff_value',
  BREAKOUT = 'breakout',
  SLEEPER = 'sleeper',
  LEAGUE_WINNER = 'league_winner',
  INJURY_HISTORY = 'injury_history',
  ROOKIE = 'rookie',
  PLUS_UTILIZATION = 'plus_utilization',
  TALENTED_AF = 'talented_af',
  BUST = 'bust',
}

export enum FantasyLeagueType {
  STANDARD = 'Standard',
  TWO_QB = '2QB',
  DYNASTY_STARTUP = 'Dynasty Startup',
  DYNASTY_ROOKIE = 'Dynasty Rookie',
  IDP = 'IDP',
}

export enum FantasyProjectionSource {
  Manual = 'manual',
}

export enum FantasyScoringType {
  PPR = 'PPR',
  ZERO_POINT_FIVE_PPR = '0.5 PPR',
  STANDARD = 'Standard',
  TE_PREMIUM = 'TE Premium',
  IDP = 'IDP',
}

// this enum should hold all possible scoring types, but it should
// be narrowed down to only the ones supported by specific use cases below
export enum FantasyScoringTypeEnum {
  // start of 2022 & 2023 season rankings scoring types
  PPR = 'PPR',
  ZERO_POINT_FIVE_PPR = 'HALF_PPR',
  STANDARD = 'STANDARD',
  SUPERFLEX = 'SUPERFLEX', // not in use right now
  PPR_SUPERFLEX = 'PPR_SUPERFLEX',
  ZERO_POINT_FIVE_PPR_SUPERFLEX = 'HALF_PPR_SUPERFLEX',
  STANDARD_SUPERFLEX = 'STANDARD_SUPERFLEX',
  // start of 2023 draft rankings scoring types
  REDRAFT_PPR = 'REDRAFT_PPR',
  REDRAFT_HALF_PPR = 'REDRAFT_HALF_PPR',
  REDRAFT_NON_PPR = 'REDRAFT_NON_PPR',
  REDRAFT_2QB_PPR = 'REDRAFT_2QB_PPR',
  REDRAFT_IDP = 'REDRAFT_IDP',
  DYNASTY_STARTUP = 'DYNASTY_STARTUP',
  DYNASTY_ROOKIE = 'DYNASTY_ROOKIE',
  DYNASTY_2QB_STARTUP = 'DYNASTY_2QB_STARTUP',
  DYNASTY_2QB_ROOKIE = 'DYNASTY_2QB_ROOKIE',
  DYNASTY_IDP_STARTUP = 'DYNASTY_IDP_STARTUP',
  DYNASTY_IDP_ROOKIE = 'DYNASTY_IDP_ROOKIE',
  BEST_BALL = 'BEST_BALL',
  IDP = 'IDP',
}

export type DraftScoringTypesType =
  | FantasyScoringTypeEnum.REDRAFT_HALF_PPR
  | FantasyScoringTypeEnum.REDRAFT_IDP
  | FantasyScoringTypeEnum.DYNASTY_ROOKIE
  | FantasyScoringTypeEnum.BEST_BALL
  | FantasyScoringTypeEnum.REDRAFT_PPR
  | FantasyScoringTypeEnum.DYNASTY_STARTUP
  | FantasyScoringTypeEnum.DYNASTY_IDP_ROOKIE
  | FantasyScoringTypeEnum.REDRAFT_NON_PPR
  | FantasyScoringTypeEnum.REDRAFT_2QB_PPR
  | FantasyScoringTypeEnum.DYNASTY_2QB_ROOKIE
  | FantasyScoringTypeEnum.DYNASTY_IDP_STARTUP
  | FantasyScoringTypeEnum.DYNASTY_2QB_STARTUP

export const draftScoringTypes: DraftScoringTypesType[] = [
  FantasyScoringTypeEnum.REDRAFT_HALF_PPR,
  FantasyScoringTypeEnum.REDRAFT_IDP,
  FantasyScoringTypeEnum.DYNASTY_ROOKIE,
  FantasyScoringTypeEnum.BEST_BALL,
  FantasyScoringTypeEnum.REDRAFT_PPR,
  FantasyScoringTypeEnum.DYNASTY_STARTUP,
  FantasyScoringTypeEnum.DYNASTY_IDP_ROOKIE,
  FantasyScoringTypeEnum.REDRAFT_NON_PPR,
  FantasyScoringTypeEnum.REDRAFT_2QB_PPR,
  FantasyScoringTypeEnum.DYNASTY_2QB_ROOKIE,
  FantasyScoringTypeEnum.DYNASTY_IDP_STARTUP,
  FantasyScoringTypeEnum.DYNASTY_2QB_STARTUP,
]

export type DraftIdpScoringTypesType =
  | FantasyScoringTypeEnum.REDRAFT_IDP
  | FantasyScoringTypeEnum.DYNASTY_IDP_ROOKIE
  | FantasyScoringTypeEnum.DYNASTY_IDP_STARTUP

export const draftIdpScoringTypes: DraftIdpScoringTypesType[] = [
  FantasyScoringTypeEnum.REDRAFT_IDP,
  FantasyScoringTypeEnum.DYNASTY_IDP_ROOKIE,
  FantasyScoringTypeEnum.DYNASTY_IDP_STARTUP,
]

// Unfortunately the API was built to accept these lowercase values instead of
// our actual scoring types, so we need to kep this for backwards compatibility
export enum FantasySeasonScoringTypeParamEnum {
  PPR = 'ppr',
  HALF_PPR = 'halfPPR',
  STANDARD = 'standard',
  PPR_SUPERFLEX = 'pprSuperflex',
  HALF_PPR_SUPERFLEX = 'halfPPRSuperflex',
  STANDARD_SUPERFLEX = 'standardSuperflex',
  IDP = 'idp',
}

export const scoringTypeParamToTypeMap: Record<FantasySeasonScoringTypeParamEnum, FantasyScoringTypeEnum> = {
  [FantasySeasonScoringTypeParamEnum.PPR]: FantasyScoringTypeEnum.PPR,
  [FantasySeasonScoringTypeParamEnum.HALF_PPR]: FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR,
  [FantasySeasonScoringTypeParamEnum.STANDARD]: FantasyScoringTypeEnum.STANDARD,
  [FantasySeasonScoringTypeParamEnum.PPR_SUPERFLEX]: FantasyScoringTypeEnum.PPR_SUPERFLEX,
  [FantasySeasonScoringTypeParamEnum.HALF_PPR_SUPERFLEX]: FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR_SUPERFLEX,
  [FantasySeasonScoringTypeParamEnum.STANDARD_SUPERFLEX]: FantasyScoringTypeEnum.STANDARD_SUPERFLEX,
  [FantasySeasonScoringTypeParamEnum.IDP]: FantasyScoringTypeEnum.IDP,
}

type SeasonScoringTypeParamsType =
  | FantasySeasonScoringTypeParamEnum.PPR
  | FantasySeasonScoringTypeParamEnum.HALF_PPR
  | FantasySeasonScoringTypeParamEnum.STANDARD
  | FantasySeasonScoringTypeParamEnum.PPR_SUPERFLEX
  | FantasySeasonScoringTypeParamEnum.HALF_PPR_SUPERFLEX
  | FantasySeasonScoringTypeParamEnum.STANDARD_SUPERFLEX

export const seasonScoringTypeParams: SeasonScoringTypeParamsType[] = [
  FantasySeasonScoringTypeParamEnum.PPR,
  FantasySeasonScoringTypeParamEnum.HALF_PPR,
  FantasySeasonScoringTypeParamEnum.STANDARD,
  FantasySeasonScoringTypeParamEnum.PPR_SUPERFLEX,
  FantasySeasonScoringTypeParamEnum.HALF_PPR_SUPERFLEX,
  FantasySeasonScoringTypeParamEnum.STANDARD_SUPERFLEX,
]

type SeasonIdpScoringTypeParamsType = FantasySeasonScoringTypeParamEnum.IDP

export const seasonIdpScoringTypeParams: SeasonIdpScoringTypeParamsType[] = [FantasySeasonScoringTypeParamEnum.IDP]

export enum ManualLeagueSyncScoringTypeEnum {
  PPR = 'PPR',
  ZERO_POINT_FIVE_PPR = '0.5 PPR',
  STANDARD = 'Standard',
  SUPERFLEX = '2QB PPR',
  IDP_BALANCED = 'IDP Balanced',
  IDP_TACKLE_HEAVY = 'IDP Tackle Heavy',
  IDP_BIG_PLAY = 'IDP Big Play',
}

export enum ManualLeagueSyncDraftTypeEnum {
  SNAKE = 'Snake',
  LINEAR = 'Linear',
  AUCTION = 'Auction',
  ROOKIE_SNAKE = 'Rookie Snake',
  ROOKIE_LINEAR = 'Rookie Linear',
}

export enum ManualLeagueSyncLineUpTypeEnum {
  SET_WEEKLY = 'Set Weekly',
  BEST_BALL = 'Best Ball',
  DYNASTY = 'Dynasty',
}

export enum RotowireScoringTypeADP {
  BEST_BALL = 'BEST_BALL',
}

export enum SleeperScoringTypeADP {
  REDRAFT_PPR = 'REDRAFT_PPR',
  REDRAFT_HALF_PPR = 'REDRAFT_HALF_PPR',
  REDRAFT_2QB_PPR = 'REDRAFT_2QB_PPR',
  DYNASTY_PPR = 'DYNASTY_PPR',
  DYNASTY_HALF_PPR = 'DYNASTY_HALF_PPR',
  DYNASTY_SUPERFLEX = 'DYNASTY_SUPERFLEX',
  IDP = 'IDP',
}

export enum EspnScoringTypeADP {
  PPR = 'PPR',
}

export enum YahooScoringTypeADP {
  STANDARD = 'STANDARD',
}

export type FantasyScoringTypeADP =
  | RotowireScoringTypeADP
  | SleeperScoringTypeADP
  | EspnScoringTypeADP
  | YahooScoringTypeADP

export type FantasyProjectionScoringType =
  | FantasyScoringTypeEnum.PPR
  | FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR
  | FantasyScoringTypeEnum.STANDARD
  | FantasyScoringTypeEnum.IDP

export enum FantasySharedAdpSourceEnum {
  ROTOWIRE = 'Rotowire',
  UNDERDOG = 'Underdog',
  SLEEPER = 'Sleeper',
  ESPN = 'ESPN',
  YAHOO = 'Yahoo',
}
export type FantasySharedAdpSourceEnumType = `${FantasySharedAdpSourceEnum}`

export enum FantasyRanker {
  PFF_CONSENSUS = 'PFF Consensus',
  DWAIN = 'Dwain',
  IAN = 'Ian',
  NATHAN = 'Nathan',
  KEVIN = 'Kevin',
  JON_M = 'JonM',
}

export enum FantasySort {
  SORT_BY_RANK = 'rank.current',
  SORT_BY_ADP = 'adp',
  SORT_BY_PROJ = 'projection.points.mid',
  SORT_BY_WEEKLY_RANK = 'rank.current',
  SORT_BY_PICK_GRADE = 'pickGrade',
  SORT_BY_AVAILABILITY = 'nextRoundAvailability',
  SORT_BY_AUCTION_VALUE = 'auctionValue',
  SORT_BY_POS_RANK = 'rank.position',
}

export enum FantasyExperiences {
  FANTASY_WEEKLY_RANKINGS = 'fantasy weekly rankings',
  FANTASY_DRAFT_RANKINGS = 'fantasy draft rankings',
  FANTASY_START_SIT = 'fantasy start/sit',
  FANTASY_MATCHUPS = 'fantasy matchups',
  FANTASY_WAIVER_TARGETS = 'fantasy waiver targets',
  FANTASY_MY_TEAM = 'fantasy my team',
  LDA = 'Live Draft Assistant',
  FMDS = 'Fantasy Mock Draft Simulator',
}

export enum IdpRankingsExperiences {
  REDRAFT_IDP = 'IDP Draft Rankings',
  DYNASTY_IDP_STARTUP = 'IDP Startup Draft Rankings',
  DYNASTY_IDP_ROOKIE = 'IDP Rookie Draft Rankings',
  IDP_WEEKLY = 'IDP Weekly Rankings',
}

export enum FantasyPlayerHeadShotLocations {
  PLAYER_COMPARE_ORIGINAL_PLAYER = 'Player Compare - Original Player',
  PLAYER_COMPARE_AVAILABLE_PLAYER = 'Player Compare - Available Players',
  PLAYER_COMPARE_SELECTED_PLAYER = 'Player Compare - Selected Player',
  PLAYER_COMPARE_COMPARE_ROW = 'Player Compare - Compare Row',
  RANK_CARD = 'Rank Card',
  START_SIT_CALCULATOR_PANE = 'Results Calculator Pane',
  START_SIT_RESULT = 'Result',
  START_SIT_PLAYERS_LIST = 'Players List',
  ANALYSTS_SELECTION = 'Analysts Selection',
  PROJECTIONS = 'Projections',
}

export type FantasyPositions =
  | FantasyPosition.QB
  | FantasyPosition.RB
  | FantasyPosition.WR
  | FantasyPosition.TE
  | FantasyPosition.FLEX
  | FantasyPosition.DEF
  | FantasyPosition.K
  | FantasyPosition.DST
  | FantasyPosition.COV
  | FantasyPosition.SFL
  | FantasyPosition.CB
  | FantasyPosition.LB
  | FantasyPosition.DB
  | FantasyPosition.DL
  | FantasyPosition.DT
  | FantasyPosition.S
  | FantasyPosition.ED

export type FantasyTags =
  | FantasyTag.INJURY_HISTORY
  | FantasyTag.BUST
  | FantasyTag.BREAKOUT
  | FantasyTag.PFF_VALUE
  | FantasyTag.TALENTED_AF
  | FantasyTag.SLEEPER
  | FantasyTag.ROOKIE
  | FantasyTag.LEAGUE_WINNER

export type FantasyInSeasonTagNames = 'high_ou'

export type FantasyAdpTrend = 'up' | 'down' | null

export type FantasyPickNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null

export interface RankWithPlayerId {
  id?: string
  playerId: number
  name: string
  rank: number | string
  bottomLine: string
  analysis: string
}

// TODO: Remove SeasonInSeasonRanks, FantasyInSeasonRankers, and FantasyInSeasonScoreTypes once we update all
// clients to use flat rank object
type SeasonInSeasonRanks = {
  fl: {
    rank: number | null
  }
  [key: string /* qb | rb | wr | te | k  */]: {
    rank: number | null // this isn't null, but typescript doesn't like when it isn't
  }
}

interface FantasyInSeasonRankers {
  consensus: SeasonInSeasonRanks
  kevin?: SeasonInSeasonRanks
  nathan: SeasonInSeasonRanks
  ian?: SeasonInSeasonRanks
}

interface FantasyInSeasonScoreTypes {
  [key: string /* standard | halfPPR | ppr  */]: FantasyInSeasonRankers
}

export interface FantasyInSeasonTags {
  name: string
  value: string
}

export interface FantasyMatchupPlayer {
  playerId: number
  firstName: string
  lastName: string
  advantageAgainst: number
  projectedSnaps: number
}

export interface FantasyMatchupOverall {
  overallAdvantage: number
}

export interface FantasyMatchup extends FantasyMatchupOverall {
  matchupPlayers: FantasyMatchupPlayer[]
}

export interface CheatsheetPlayer {
  playerId: number
  playerName: string
  position: string
  bye: number
  team: string
  availabilityPercentage: number
  tier: number
  rank: number
  isDrafted: boolean
  showPosition?: boolean
}

export interface CheatsheetPlayerSectionData {
  title: string
  data: CheatsheetPlayer[]
}

export interface FantasyInSeasonRank {
  current: number | null
  position: number | null
}

export interface FantasyInSeasonPlayer {
  id: number
  playerId: number // hoping to remove this in favor of id
  firstName: string
  lastName: string
  height: number
  weight: number
  position: string
  jerseyNumber: number
  birthDate: string | null
  teamId: number
  teamAbbreviation: string
  byeWeek: number
  nextGameTime: number
  nextGameLocation: string
  nextGameStadium: string
  nextGameWeek: number
  nextOpponentId: number
  nextOpponentAbbreviation: string
  homeTeamId: number
  gameStatus: string | null
  tags: FantasyInSeasonTags[] | null
  rank: FantasyInSeasonRank
  rankers: FantasyInSeasonScoreTypes | null // deprecated, but still here to avoid breaking clients
  projection: FantasyProjection | null
  matchup: FantasyMatchupOverall | null
}

export function isFantasyInSeasonPlayer(
  player: Partial<FantasyInSeasonPlayer> | Partial<FantasyInSeasonDST>
): player is FantasyInSeasonPlayer {
  if (player.position !== FantasyPosition.DEF) {
    return true
  }
  return false
}

export type WeeklyRankType = {
  [key: string]: {
    rank: number | null
  }
}
export interface ProjectionEntity {
  points: {
    low: number
    mid: number
    high: number
  }
  position?: {
    low: number
    mid: number
    high: number
  }
}

export type WeeklyRankSet = {
  consensus: WeeklyRankType
  nathan: WeeklyRankType
  jon: WeeklyRankType
}

export interface WeeklyRankingsEntity {
  rank: {
    current: number | null
    position: number | null
  }
  status: string | null
  city: string
  teamName: string
  rankers: WeeklyRankSet
  projection: ProjectionEntity
  first_name: string
  last_name: string
  id: number
  position: FantasyPositions
  opposition: string
  matchTime: number
  gameWeek: number
  team: string
  tags: Array<FantasyInSeasonTags>
  matchup: null | { overallAdvantage: number }
  isHomeGame: boolean
  dob: string
  height: number
  weight: number
  bye: number
}

export interface FantasyInSeasonDST {
  id: number
  playerId: number // actually teamId, hoping to remove this in favor of id
  teamId: number
  position: string
  city: string
  name: string
  abbreviation: string
  primaryColor: string
  secondaryColor: string
  byeWeek: number
  nextGameTime: number
  nextGameLocation: string
  nextGameStadium: string
  nextGameWeek: number
  nextOpponentId: number
  nextOpponentAbbreviation: string
  homeTeamId: number
  rank: FantasyInSeasonRank
  rankers: FantasyInSeasonScoreTypes | null // deprecated, but still here to avoid breaking clients
  projection: FantasyProjection | null
}

// we should remove these two union types at some point and use a single interface
// but I didn't want to break other things using the existing types and make the diff harder to read
export type FantasyInSeasonPick = FantasyInSeasonPlayer | FantasyInSeasonDST

export interface WrCbMatchupPlayer {
  playerId: number
  firstName: string
  lastName: string
  position: string
  teamId: number
  isHomeGame?: boolean
  teamAbbreviation: string
  gameStatus: string | null
  matchup: FantasyMatchup
  nextGameTime: number
  nextGameWeek: number
  nextOpponentId: number
  nextOpponentAbbreviation: string
}

export function isFantasyInSeasonDST(
  player: Partial<FantasyInSeasonPlayer> | Partial<FantasyInSeasonDST>
): player is FantasyInSeasonDST {
  if (player.position === FantasyPosition.DEF) {
    return true
  }
  return false
}

export enum DraftPickStatus {
  KEEPER = 'keeper',
  PICKED = 'picked',
  IN_PROGRESS = 'in-progress',
  PENDING = 'pending',
}

export enum RankCardVariant {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}

export enum PlayerStatsModalLocation {
  STANDALONE_RANKINGS = 'standalone rankings',
  MOCK_DRAFT_SIMULATOR = 'mock draft simulator',
  LIVE_DRAFT_ASSISTANT = 'live draft assistant',
  MY_TEAM_DASHBOARD = 'my team dashboard',
}

export enum FantasyPages {
  FANTASY_DRAFT_RANKINGS = 'FANTASY DRAFT RANKINGS',
  FANTASY_WEEKLY_RANKINGS = 'FANTASY WEEKLY RANKINGS',
  FANTASY_CHEAT_SHEET = 'FANTASY DRAFT CHEAT SHEET',
  FANTASY_MOCK_DRAFT_SIMULATOR = 'FANTASY MOCK DRAFT SIMULATOR',
  FANTASY_LIVE_DRAFT_ASSISTANT = 'LIVE DRAFT ASSISTANT',
}

export const HAS_SEEN_RESYNC_LEAGUES_MODAL = 'hasSeenResyncLeaguesModal'
export const HAS_SEEN_LDA_SLEEPER_KEEPERS_INFO_MODAL = 'hasSeenLdaSleeperKeepersInfoModal'
export const HAS_SEEN_FMDS_SLEEPER_KEEPERS_INFO_MODAL = 'hasSeenFmdsSleeperKeepersInfoModal'
export const HAS_SEEN_UPDATE_EXTENSION_MODAL = 'hasSeenUpdateExtensionModal'

export const LATEST_CHROME_EXTENSION_VERSION = 0.2

export type FormattedDataItem = {
  'Overall Rank': string | number | null
  'Full Name': string
  'Team Abbreviation': string
  Position: FantasyPositions
  'Position Rank': string | number | null
  'Bye Week': number
  ADP?: string | number
  'Projected Points'?: number
  'Auction Value'?: string | number
}

export const SCORING_TYPES = [
  {
    title: 'REDRAFT',
    options: [
      { name: 'PPR', value: FantasyScoringTypeEnum.REDRAFT_PPR },
      { name: 'Half PPR', value: FantasyScoringTypeEnum.REDRAFT_HALF_PPR },
      { name: 'NON-PPR', value: FantasyScoringTypeEnum.REDRAFT_NON_PPR },
      { name: '2-QB PPR', value: FantasyScoringTypeEnum.REDRAFT_2QB_PPR },
      { name: 'IDP', value: FantasyScoringTypeEnum.REDRAFT_IDP },
    ],
  },
  {
    title: 'DYNASTY',
    options: [
      { name: 'Startup', value: FantasyScoringTypeEnum.DYNASTY_STARTUP },
      { name: 'Rookie', value: FantasyScoringTypeEnum.DYNASTY_ROOKIE },
      { name: '2QB Startup', value: FantasyScoringTypeEnum.DYNASTY_2QB_STARTUP },
      { name: '2QB Rookie', value: FantasyScoringTypeEnum.DYNASTY_2QB_ROOKIE },
      { name: 'IDP Startup', value: FantasyScoringTypeEnum.DYNASTY_IDP_STARTUP },
      { name: 'IDP Rookie', value: FantasyScoringTypeEnum.DYNASTY_IDP_ROOKIE },
    ],
  },
  {
    title: 'BEST BALL',
    options: [{ name: 'Best Ball', value: FantasyScoringTypeEnum.BEST_BALL }],
  },
]

export enum RankingsType {
  DRAFT = 'draft',
  WEEKLY = 'weekly',
}

export enum RankingsTabIndex {
  DRAFT = 0,
  WEEKLY = 1,
}
