import type { MDSLeagueSettings } from '@pff-consumer/schema'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'

export const transformLeagueSettingsForMds = (leagueSettings: MDSLeagueSettings, leagueKeepers: Keeper[]) => {
  const updatedKeeperPicks = {
    player_id: leagueKeepers.map((keeper: Keeper) => keeper.playerId),
    pick: leagueKeepers.map((keeper: Keeper) => keeper.pickNumber),
  }

  return {
    ...leagueSettings,
    keeper_dict: updatedKeeperPicks,
  }
}
