import type { ConnectInsight, FootballPlayer } from '@pff-consumer/schema'

export function getInsightWithPlayer(connectInsights: ConnectInsight[], players: FootballPlayer[]) {
  // Prepare Player information player insights
  const playerIdMap = players.reduce(
    (acc: Record<number, FootballPlayer>, player) => ({ ...acc, [player.playerId]: player }),
    {}
  )

  const playerInsights = connectInsights.map((insights) => ({
    ...insights,
    player: playerIdMap[insights.playerId],
  }))

  return { playerInsights, players }
}
