import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  DraftedPlayerInfo,
  Drafter,
  DraftPickOrder,
  DraftTypes,
  FANTASY_CONSTANTS,
  FantasyAdpTrend,
  FantasyLeagueType,
  FantasyPosition,
  FantasyPositions,
  FantasyProvider,
  FantasyRanker,
  FantasyRoster,
  FantasyScoringType,
  FantasyScoringTypeEnum,
  FantasySort,
  FantasyTags,
  FunFacts,
  GetMdsResultsRequest,
  HostedSites,
  League,
  LeagueSettingsStarters,
  LiveDraftStatus,
  MatchupRecord,
  MDSAllPlayers,
  MDSLeagueSettings,
  MDSPickInfo,
  DraftAnalyticsInfo,
  NextPickInfo,
  NextPickInfoSuggestion,
  NextPickInfoSuggestionsTransformedResponse,
  NextRoundInfo,
  PlayerNextRoundAvailability,
  PlayerPickGrades,
  PositionGrade,
  LDAPlayerDraftedInfo,
  FantasyExperiences,
  LDARoundStartedInfo,
  RankingsType,
  type WeeklyRankingsEntity,
  type ProjectionEntity,
} from '@pff-consumer/schema'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'
import type { ManualLeagueSettings } from '@pff-consumer/schema/fantasy-mds/manual-league-settings'
import { omit, orderBy as lodashOrderBy, round } from 'lodash'
import type { LiveDraftPick, LiveDraftStatusType } from '@pff-consumer/schema/fantasy-live-draft/picks'
import { DraftStrategyTypes } from '@pff-consumer/schema/fantasy-mds/draft-strategy-types'
import {
  calculateRosterSize,
  extractId,
  generateDraftPicksList,
  getPffPlayerId,
  isFantasyPlayerIdDST,
  sorters,
  transformSyncedSettings,
  getLatestDraftedPlayerId,
  findSuggestedPlayerIndex,
  calculateDraftGrade,
  getAvailabilityPercent,
  getDstName,
} from '@pff-consumer/utils'
import { convertToStringId } from '@pff-consumer/utils/lib/fantasy/draft/convertToStringId'
import { PlayerPosition, PlayerVsTeamMatchup as SDKPlayerMatchup } from '@pro-football-focus/pffkit-js-core'
import { Matchup, MatchupPlayer } from '@pff-consumer/schema/sdk/matchups'
import { transformDraftPicks } from './utils/transform-draft-picks'
import { createDeepEqualSelector } from '../createDeepEqualSelector'
import { getPlayersFilteredByName } from './utils/get-players-filtered-by-name'
import { getPlayersFilteredByTags } from './utils/get-players-filtered-by-tags'
import { getLatestLiveDraftPickWithoutKeepers } from './utils/get-latest-live-draft-pick-without-keepers'
import { getCurrentLiveDraftRoundAndPickNumber } from './utils/get-current-live-draft-round-and-pick-number'
import { getTeamKeepers } from './utils/get-team-keepers'
import { getDraftStrategy } from './utils/get-draft-strategy'
import type { AppRootState } from '../../store/store'
import { transformLeagueSettingsForMds } from '../../store/utils/transform-league-settings-for-mds'
import { transformManualSettings } from '../../store/utils/transform-manual-settings'
import { getScoringType } from './utils/get-scoring-type'

export const FANTASY_FILTER_KEY = 'fantasy'

export type FantasySortOption =
  | FantasySort.SORT_BY_RANK
  | FantasySort.SORT_BY_ADP
  | FantasySort.SORT_BY_PROJ
  | FantasySort.SORT_BY_WEEKLY_RANK
  | FantasySort.SORT_BY_PICK_GRADE
  | FantasySort.SORT_BY_AVAILABILITY
  | FantasySort.SORT_BY_AUCTION_VALUE
  | FantasySort.SORT_BY_POS_RANK

export type FantasySortOrder = 'asc' | 'desc' | true | false
export interface FantasySortEntity {
  sortBy: FantasySortOption
  orderBy: FantasySortOrder
}

export interface ReduxLiveDraftPick extends LiveDraftPick {
  rankingsObject: RankingsEntity
}

interface DefaultLeagueSettings {
  bench_spots: MDSLeagueSettings['bench_spots']
  fantasy_players_in_league: MDSLeagueSettings['fantasy_players_in_league']
  rounds: MDSLeagueSettings['rounds']
  user_pick: MDSLeagueSettings['user_pick']
  starting_WR: MDSLeagueSettings['starting_WR']
  starting_RB: MDSLeagueSettings['starting_RB']
  starting_TE: MDSLeagueSettings['starting_TE']
  starting_QB: MDSLeagueSettings['starting_QB']
  starting_flex: MDSLeagueSettings['starting_flex']
  starting_superflex: MDSLeagueSettings['starting_superflex']
  starting_K: MDSLeagueSettings['starting_K']
  starting_DST: MDSLeagueSettings['starting_DST']
  user_strategy_zero_rb: MDSLeagueSettings['user_strategy_zero_rb']
  user_strategy_late_qb: MDSLeagueSettings['user_strategy_late_qb']
  user_strategy_stacking: MDSLeagueSettings['user_strategy_stacking']
  cpu_team_affinity: MDSLeagueSettings['cpu_team_affinity']
  cpu_team_aversion: MDSLeagueSettings['cpu_team_aversion']
  linear_drafting: MDSLeagueSettings['linear_drafting']
  third_round_reversal: MDSLeagueSettings['third_round_reversal']
}

type LeagueSettingsStartersKeys = keyof LeagueSettingsStarters

type DraftRoomSuggestion = NextPickInfoSuggestionsTransformedResponse['suggestions']
interface SuggestionsWithPlayerInfo {
  round: number
  suggestions: NextPickInfoSuggestion[]
}

// TODO: Move draft items to their own subkey
export interface FantasyState {
  filters: FiltersEntity
  // TODO: Remove once native is updated to follow new RTK listener pattern
  rankings: Array<RankingsEntity>
  draftRankingsUpdatedTime: string | null
  mutatedRankings: Array<RankingsEntity>
  mutatingRankings: boolean
  unfilteredRankings: Array<RankingsEntity>
  syncedLeagues: Array<League>
  playerCompare: {
    selectedPlayerIds: {
      [key: number]: number
    }
  }
  rankerInfo: Array<RankerInfoEntity>
  sort: FantasySortEntity
  leagueSync: {
    fantasyProvider: FantasyProvider | null
    activeStep: number
    uid: string | null
    leagueName: string | null
  }
  draftRoom: {
    drafting: boolean
    draftEnded: boolean
    draftPickAnimations: {
      [key: number]: boolean
    }
    draftPicks: string[]
    initialLoad: boolean
    leagueSettings: MDSLeagueSettings | DefaultLeagueSettings
    liveDraftPicks: {
      [key: number]: LiveDraftPick
    }
    leagueKeepers: Keeper[]
    teamKeepers: string[]
    leagueDrafters: Drafter[]
    draftPickOrder: DraftPickOrder
    liveDraftStatus: LiveDraftStatusType | ''
    liveDraftHasContextualMessage: boolean
    liveDraftMode: boolean
    manualLeagueSettings: ManualLeagueSettings
    mdsEndingEarly: boolean
    otherTeamPicks: string[]
    players: MDSAllPlayers | null
    suggestionsError: boolean
    suggestionsLoading: boolean
    pickInfo: MDSPickInfo | { round: MDSPickInfo['round'] }
    positionsAllowedToBeDrafted: string[]
    playerInfoMap: Record<string, Omit<DraftedPlayerInfo, 'status'>>
    playerNextRoundAvailability: PlayerNextRoundAvailability
    playerPickGrades: PlayerPickGrades
    selectedSuggestedPlayerIndex: number
    selectedSyncedLeague: League | null
    showDraftedPlayers: boolean
    suggestions: DraftRoomSuggestion
    userOnClock: boolean
    userPicks: string[]
    userPicksPickGrades: number[]
    selectedLeague: League | null
    webSocketHasReceivedMessage: boolean
  }
  inSeason: {
    activeTabIndex: number
    week: number | null
    startSit: {
      playerAId: number | null
      playerBId: number | null
      funFacts: FunFacts[]
      playerAPosition: FantasyPosition | null
      rankings: Array<WeeklyRankingsEntity>
      filters: InSeasonFiltersEntity
    }
    weeklyRankings: {
      rankings: Array<WeeklyRankingsEntity>
      unfilteredRankings: Array<WeeklyRankingsEntity>
      filters: InSeasonFiltersEntity
      updatedTime: string | null
      sort: FantasySortEntity
    }
    myTeam: {
      matchup: MatchupRecord['matchup'] | null
      isMatchupLoading: boolean
      isOptimizeToggleOn: boolean
      rosterMatchup: {
        availableMatchup: Matchup[]
        selectedMatchup: Matchup | null
        opponent: MatchupPlayer | null
        playerGradeMap: Record<number, PositionGrade>
      }
      playerRankingsMap: Record<WeeklyRankingsEntity['id'], WeeklyRankingsEntity>
    }
    matchups: {
      selectedPosition: PlayerPosition
    }
    selectedLeague: League | null
    isLeagueUnselected: boolean
    rosterPlayers: FantasyRoster
    isSyncLeagueModalVisible: boolean
  }
  standaloneRankings: {
    activeTab: number
  }
}

export interface InSeasonFiltersEntity {
  position: FantasyPositions
  scoringType: FantasyScoringType
  tags: Array<string>
  search: string
}

export interface FiltersEntity {
  position: Array<FantasyPositions>
  leagueType: FantasyLeagueType
  scoringType: FantasyScoringTypeEnum
  page: number
  pageSize: number
  search: string
  tags: Array<string>
}

export type WeeklyRankType = {
  [key: string]: {
    rank: number | null
  }
}

export interface RankEntity {
  current: number | string
  low: number
  high: number
  position: number
  ranker: FantasyRanker.PFF_CONSENSUS
}

export interface RankerInfoEntity {
  name: string
  id: number
  analysis: string
  bottomLine: string
  rank: number | string
  positionRank: number | string
}

export interface OpposingMatchupData {
  playerId: number
  advantageAgainst: number
  first_name: string
  last_name: string
  projectedSnaps: number
}

export interface MatchupData {
  overallAdvantage: number
  matchupPlayers: Array<OpposingMatchupData>
}

export interface MatchupsEntity extends SDKPlayerMatchup {
  id: number
  first_name: string
  last_name: string
  position: FantasyPositions
  team: string
  opposition: string
  matchTime: number
  gameWeek: number
  matchup: MatchupData
}

export interface RankingsEntity {
  adpPosition?: number
  adpTrend: FantasyAdpTrend
  adp: number
  adpSource: string
  bye: number
  rank: RankEntity
  projection: ProjectionEntity
  tags: Array<FantasyTags>
  dob: string
  first_name: string
  last_name: string
  jersey_number?: string | number
  id: number
  position: FantasyPositions
  height: number
  weight: number
  team: string
  teamName: string
  teamCity: string
  pickGrade?: number
  tier?: number
  nextRoundAvailability: number
  expertAnalysis: string[]
  auctionValue?: number
}

export type DraftRoomSettingsPositions = 'qb' | 'rb' | 'wr' | 'te' | 'flex' | 'superflex' | 'dst' | 'k'

export const fantasyAdapter = createEntityAdapter<FantasyState>()

const DEFAULT_IN_SEASON_FILTERS = {
  position: FantasyPosition.QB,
  scoringType: FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.PPR],
  tags: [],
  search: '',
  page: 1,
  pageSize: 450,
}

export const initialFantasyState: FantasyState = fantasyAdapter.getInitialState({
  filters: {
    page: 1,
    pageSize: 450,
    position: [],
    leagueType: FANTASY_CONSTANTS.API_LEAGUE_TYPE[FantasyLeagueType.STANDARD],
    scoringType: FantasyScoringTypeEnum.REDRAFT_PPR,
    search: '',
    tags: [],
  },
  draftAnalystStrategy: [],
  draftSettings: {
    teamSize: 12,
    pickNumber: null,
    analyst: FantasyRanker.NATHAN,
    round: 1,
    leagueType: FANTASY_CONSTANTS.API_LEAGUE_TYPE[FantasyLeagueType.STANDARD],
    scoringType: FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.PPR],
  },
  // Used for league sync flow
  leagueSync: {
    fantasyProvider: null,
    activeStep: 1,
    uid: null,
    leagueName: null,
  },
  syncedLeagues: [],
  // Used for LDA and MDS
  draftRoom: {
    draftEnded: false,
    draftPickAnimations: {},
    draftPicks: [],
    drafting: false,
    initialLoad: true,
    webSocketHasReceivedMessage: false,
    liveDraftMode: false,
    liveDraftPicks: {},
    leagueDrafters: [],
    leagueKeepers: [],
    teamKeepers: [],
    draftPickOrder: {},
    liveDraftStatus: '',
    liveDraftHasContextualMessage: true,
    leagueSettings: {
      bench_spots: 0,
      fantasy_players_in_league: 1,
      rounds: 1,
      user_pick: 1,
      starting_WR: 0,
      starting_RB: 0,
      starting_TE: 0,
      starting_QB: 0,
      starting_flex: 0,
      starting_superflex: 0,
      third_round_reversal: 0,
      linear_drafting: 0,
      starting_K: 0,
      starting_DST: 0,
      user_strategy_zero_rb: 0,
      user_strategy_late_qb: 0,
      user_strategy_stacking: 0,
      cpu_team_affinity: [[]],
      cpu_team_aversion: [[]],
    },
    manualLeagueSettings: {
      numberOfTeams: 12,
      userPick: 1,
      rounds: 15,
      starters: {
        qb: 1,
        rb: 2,
        wr: 2,
        te: 1,
        flex: 1,
        superflex: 0,
        dst: 1,
        k: 1,
      },
      bench: 6,
      scoringType: 'PPR',
      draftType: DraftTypes.SNAKE,
    },
    mdsEndingEarly: false,
    otherTeamPicks: [],
    suggestionsError: false,
    suggestionsLoading: false,
    playerInfoMap: {},
    players: null,
    playerNextRoundAvailability: {},
    playerPickGrades: {},
    pickInfo: {
      round: 1,
    },
    positionsAllowedToBeDrafted: ['QB', 'WR', 'RB', 'TE', 'K', 'DST', 'ED', 'CB', 'LB', 'S', 'DT'],
    selectedLeague: null,
    selectedSuggestedPlayerIndex: 0,
    selectedSyncedLeague: null,
    showDraftedPlayers: false,
    suggestions: {
      round: 1,
      suggestions: [],
    },
    userOnClock: false,
    userPicks: [],
    userPicksPickGrades: [],
  },
  playerCompare: {
    selectedPlayerIds: {},
  },
  inSeason: {
    activeTabIndex: 0,
    week: null,
    weeklyRankings: {
      rankings: [],
      unfilteredRankings: [],
      filters: DEFAULT_IN_SEASON_FILTERS,
      updatedTime: null,
      sort: { sortBy: FantasySort.SORT_BY_WEEKLY_RANK, orderBy: 'asc' },
    },
    startSit: {
      playerAId: null,
      playerBId: null,
      playerAPosition: null,
      funFacts: [],
      rankings: [],
      filters: DEFAULT_IN_SEASON_FILTERS,
    },
    myTeam: {
      matchup: null,
      isMatchupLoading: false,
      isOptimizeToggleOn: false,
      rosterMatchup: {
        availableMatchup: [],
        selectedMatchup: null,
        opponent: null,
        playerGradeMap: {},
      },
      playerRankingsMap: {},
    },
    matchups: {
      selectedPosition: PlayerPosition.WideReceiver,
    },
    selectedLeague: null,
    isLeagueUnselected: false,
    rosterPlayers: { starters: [], bench: [] },
    isSyncLeagueModalVisible: false,
  },
  mutatingRankings: false,
  mutatedRankings: [],
  rankings: [],
  unfilteredRankings: [],
  rankerInfo: [],
  sort: { sortBy: FantasySort.SORT_BY_RANK, orderBy: 'asc' },
  draftRankingsUpdatedTime: null,
  standaloneRankings: {
    activeTab: 0,
  },
})

const EMPTY_ARRAY: string[] = []

export const fantasySlice = createSlice({
  name: FANTASY_FILTER_KEY,
  initialState: initialFantasyState,
  reducers: {
    removePlayerToCompareWith: (state, { payload }) => {
      state.playerCompare.selectedPlayerIds = omit(state.playerCompare.selectedPlayerIds, payload.id)
    },
    setPlayerToCompareWith: (state, { payload }) => {
      const { id, selectedPlayerId } = payload
      state.playerCompare.selectedPlayerIds[id] = selectedPlayerId
    },
    setInSeasonActiveTabIndex: (state, { payload }) => {
      state.inSeason.activeTabIndex = payload
    },
    setInSeasonWeeklyRankingsSearchTerm: (state, { payload }) => {
      state.inSeason.weeklyRankings.filters.search = payload
    },
    setInSeasonStartSitSearchTerm: (state, { payload }) => {
      state.inSeason.startSit.filters.search = payload
    },
    setSearchTerm: (state, { payload }) => {
      state.filters.search = payload
    },
    updateInSeasonWeeklyRankingsFilterPosition: (state, { payload }) => {
      state.inSeason.weeklyRankings.filters.position = payload
    },
    updateInSeasonStartSitFilterPosition: (state, { payload }) => {
      state.inSeason.startSit.filters.position = payload
    },
    updateFilterPosition: (state, { payload }) => {
      state.filters.position = payload
    },
    updateFilterLeagueType: (state, { payload }) => {
      state.filters.leagueType = payload
    },
    updateInSeasonWeeklyRankingsFilterScoringType: (state, { payload }) => {
      state.inSeason.weeklyRankings.filters.scoringType = payload
    },
    updateInSeasonStartSitFilterScoringType: (state, { payload }) => {
      state.inSeason.startSit.filters.scoringType = payload
    },
    updateFilterScoringType: (state, { payload }) => {
      state.filters.scoringType = payload
    },
    updateInSeasonSelectedLeague: (state, { payload }) => {
      state.inSeason.selectedLeague = payload
    },
    updateInSeasonIsLeagueUnselected: (state, { payload }) => {
      state.inSeason.isLeagueUnselected = payload
    },
    updateInSeasonMyTeamMatchup: (state, { payload }) => {
      state.inSeason.myTeam.matchup = payload
    },
    updateInSeasonMyTeamPlayerGradeMap: (state, { payload }) => {
      state.inSeason.myTeam.rosterMatchup.playerGradeMap = payload
    },
    updateInSeasonMyTeamRosterMatchup: (state, { payload }) => {
      state.inSeason.myTeam.rosterMatchup.availableMatchup = payload
    },
    updateInSeasonMyTeamSelectedMatchup: (state, { payload }) => {
      state.inSeason.myTeam.rosterMatchup.selectedMatchup = payload
    },
    updateInSeasonMyTeamSelectedOpponent: (state, { payload }) => {
      state.inSeason.myTeam.rosterMatchup.opponent = payload
    },
    updateInSeasonMyTeamMatchupLoading: (state, { payload }) => {
      state.inSeason.myTeam.isMatchupLoading = payload
    },
    updateInSeasonMyTeamIsOptimizeToggleOn: (state, { payload }) => {
      state.inSeason.myTeam.isOptimizeToggleOn = payload
    },
    updateInSeasonMyTeamPlayerRankingsMap: (state, { payload }) => {
      const playerInfo: Record<number, WeeklyRankingsEntity> = {}
      payload.forEach((player: WeeklyRankingsEntity) => {
        playerInfo[player.id] = {
          ...player,
        }
      })

      state.inSeason.myTeam.playerRankingsMap = playerInfo
    },
    updateInSeasonWeeklyRankingsFilterTags: (state, { payload }) => {
      state.inSeason.weeklyRankings.filters.tags = payload
    },
    updateInSeasonStartSitFilterTags: (state, { payload }) => {
      state.inSeason.startSit.filters.tags = payload
    },
    updateFilterTags: (state, { payload }) => {
      state.filters.tags = payload
    },
    updateCurrentGameWeek: (state, { payload }) => {
      state.inSeason.week = payload
    },
    updateAllLiveDraftPicks: (state, { payload }: { payload: LiveDraftPick[] }) => {
      payload.forEach((pick) => {
        state.draftRoom.liveDraftPicks[pick.draftPick] = pick
        const pffPlayerId = getPffPlayerId(pick.draftedPlayerId)
        let stringId: string = ''
        if (!pffPlayerId) {
          console.error('unable to find rankings match for: ', payload)
          stringId = `pff-unknown-${pick.draftedPlayerId}`
        } else {
          // Add fallback to check if the id is less or equal to max team id as we wont always get their position
          const isDST = pick.draftedPlayer.includes(FantasyPosition.DST) || isFantasyPlayerIdDST(pffPlayerId)
          stringId = convertToStringId(pffPlayerId, isDST)
        }

        // Do not add duplicates - need to change draft picks to be object in future
        if (state.draftRoom.draftPicks.includes(stringId)) {
          return
        }

        state.draftRoom.draftPicks[pick.draftPick - 1] = stringId

        if (pick.drafteeName?.toLowerCase() === state.draftRoom.selectedSyncedLeague?.team_name.toLowerCase()) {
          state.draftRoom.userPicks.push(stringId)
        }
      })
    },
    updateLiveDraftPick: (state, { payload }) => {
      const pffPlayerId = getPffPlayerId(payload.draftedPlayerId)

      let stringId = ''
      if (!pffPlayerId) {
        console.error('unable to find rankings match for: ', payload)
        stringId = `pff-unknown-${payload.draftedPlayerId}`
      } else {
        // Add fallback to check if the id is less or equal to max team id as we wont always get their position
        const isDST = payload.draftedPlayer.includes(FantasyPosition.DST) || isFantasyPlayerIdDST(pffPlayerId)
        stringId = convertToStringId(pffPlayerId, isDST)
      }

      state.draftRoom.liveDraftPicks[payload.draftPick] = payload

      // Do not add duplicates - need to change draft picks to be object in future
      if (state.draftRoom.draftPicks.includes(stringId)) {
        return
      }
      state.draftRoom.draftPicks[payload.draftPick - 1] = stringId

      if (payload.drafteeName?.toLowerCase() === state.draftRoom.selectedSyncedLeague?.team_name.toLowerCase()) {
        state.draftRoom.userPicks.push(stringId)
      }
    },
    updateWebSocketReceivedMessage: (state, { payload }) => {
      state.draftRoom.webSocketHasReceivedMessage = payload
    },
    updateDraftRoomPlayerPickGrades: (state, { payload }) => {
      state.draftRoom.playerPickGrades = payload
    },
    updateDraftRoomPlayerNextRoundAvailability: (state, { payload }) => {
      state.draftRoom.playerNextRoundAvailability = payload
    },
    updateDraftRoomSelectedSuggestedPlayerIndex: (state, { payload }) => {
      state.draftRoom.selectedSuggestedPlayerIndex = payload
    },
    updateMdsDraftPicks: (state, { payload }) => {
      state.draftRoom.draftPicks.push(payload)
    },
    updateDraftRoomCurrentRound: (state, { payload }) => {
      state.draftRoom.pickInfo.round = payload
    },
    updateAllMdsDraftPicks: (state, { payload }) => {
      state.draftRoom.draftPicks = payload
    },
    updateAllUserPicks: (state, { payload }) => {
      state.draftRoom.userPicks = payload
    },
    updateUserPicks: (state, { payload }) => {
      state.draftRoom.userPicks.push(payload)
    },
    updateAllUserPicksPickGrades: (state, { payload }) => {
      state.draftRoom.userPicksPickGrades = payload
    },
    updateUserPicksPickGrades: (state, { payload }) => {
      state.draftRoom.userPicksPickGrades.push(payload)
    },
    updatePositionsAllowedToBeDrafted: (state, { payload }) => {
      state.draftRoom.positionsAllowedToBeDrafted = payload
    },
    updateDraftRoomSuggestionsError: (state, { payload }) => {
      state.draftRoom.suggestionsError = payload
    },
    updateDraftRoomSuggestionsLoading: (state, { payload }) => {
      state.draftRoom.suggestionsLoading = payload
    },
    updateDraftRoomDrafting: (state, { payload }) => {
      state.draftRoom.drafting = payload
    },
    updateDraftRoomLeagueSettings: (state, { payload }) => {
      state.draftRoom.leagueSettings = payload
    },
    updateOtherTeamPicks: (state, { payload }) => {
      state.draftRoom.otherTeamPicks.push(payload)
    },
    updateInSeasonWeeklyRankings: (state, { payload }) => {
      state.inSeason.weeklyRankings.rankings = payload
    },
    updateInSeasonWeeklyRankingsSelectedSort: (state, { payload }) => {
      const { sortBy, orderBy } = { ...state.inSeason.weeklyRankings.sort }
      let order = orderBy

      if (payload === sortBy) {
        order = orderBy === 'asc' ? 'desc' : 'asc'
      } else if ([FantasySort.SORT_BY_WEEKLY_RANK].includes(payload)) {
        order = 'desc'
      }
      state.inSeason.weeklyRankings.sort = { sortBy: payload, orderBy: order }
    },
    updateInSeasonWeeklyUnfilteredRankings: (state, { payload }) => {
      state.inSeason.weeklyRankings.unfilteredRankings = payload
    },
    updateInSeasonStartSitRankings: (state, { payload }) => {
      state.inSeason.startSit.rankings = payload
    },
    updateMutatedRankings: (state, { payload }) => {
      state.mutatedRankings = payload
    },
    updateRankings: (state, { payload }) => {
      state.rankings = payload
    },
    updateDraftRankingsUpdatedTime: (state, { payload }) => {
      state.draftRankingsUpdatedTime = payload
    },
    updateWeeklyRankingsUpdatedTime: (state, { payload }) => {
      state.inSeason.weeklyRankings.updatedTime = payload
    },
    updateUnfilteredRankings: (state, { payload }) => {
      state.unfilteredRankings = payload
    },
    updateStartSitPlayerAId: (state, { payload }) => {
      state.inSeason.startSit.playerAId = payload
    },
    updateStartSitPlayerAPosition: (state, { payload }) => {
      state.inSeason.startSit.playerAPosition = payload
    },
    updateStartSitPlayerBId: (state, { payload }) => {
      state.inSeason.startSit.playerBId = payload
    },
    updateSorting: (state, { payload }) => {
      let order = 'asc' as FantasySortOrder

      if (payload === state.sort.sortBy) {
        order = state.sort.orderBy === 'asc' ? 'desc' : 'asc'
      } else if (
        [
          FantasySort.SORT_BY_PROJ,
          FantasySort.SORT_BY_PICK_GRADE,
          FantasySort.SORT_BY_AVAILABILITY,
          FantasySort.SORT_BY_AUCTION_VALUE,
        ].includes(payload)
      ) {
        order = 'desc'
      }

      state.sort = { sortBy: payload, orderBy: order }
    },
    updateFunFacts: (state, { payload }) => {
      state.inSeason.startSit.funFacts = payload
    },
    updateIsUserOnTheClock: (state, { payload }) => {
      state.draftRoom.userOnClock = payload
    },
    updateDraftRoomSuggestions: (state, { payload }) => {
      state.draftRoom.suggestions = payload
    },
    updateDraftRoomPickInfo: (state, { payload }) => {
      state.draftRoom.pickInfo = payload
    },
    updateDraftRoomPlayers: (state, { payload }) => {
      state.draftRoom.players = payload
    },
    updateDraftRoomPlayerInfoMap: (state, { payload }: { payload: RankingsEntity[] }) => {
      const playerInfo: { [key: string]: Omit<DraftedPlayerInfo, 'status'> } = {}

      payload.forEach((player: RankingsEntity) => {
        const key = convertToStringId(player.id, player.position === FantasyPosition.DST)
        playerInfo[key] = {
          id: player.id,
          // eslint-disable-next-line camelcase
          firstName: player.first_name,
          // eslint-disable-next-line camelcase
          lastName: player.last_name,
          team: player.team,
          position: player.position,
          bye: player.bye,
          teamCity: player.teamCity,
          teamName: player.teamName,
          positionalRank: player.rank.position,
        }
      })
      // eslint-enable
      state.draftRoom.playerInfoMap = playerInfo
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchNextPickInfo: (state) => {},
    updateSyncedLeague: (state, { payload }) => {
      state.draftRoom.selectedSyncedLeague = payload

      // TODO: Move to a rtk listener
      let scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.PPR]
      if (payload === null) {
        const oldScoringTypeSettings = state.draftRoom.manualLeagueSettings.scoringType
        if (oldScoringTypeSettings === 'HALF PPR') {
          scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.ZERO_POINT_FIVE_PPR]
        } else if (oldScoringTypeSettings === 'NON-PPR') {
          scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.STANDARD]
        }
      } else {
        const recvReceptionsScore = Number(payload.league_settings.recv_receptions1)
        if (recvReceptionsScore === 0.5) {
          scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.ZERO_POINT_FIVE_PPR]
        } else if (recvReceptionsScore === 0) {
          scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.STANDARD]
        }
      }

      state.filters.scoringType = scoringType
    },
    updateManualLeagueSettings: (state, { payload }) => {
      state.draftRoom.manualLeagueSettings = payload
    },
    updateManualSettingsNumberOfTeams: (state, { payload }) => {
      state.draftRoom.manualLeagueSettings.numberOfTeams = payload
    },
    updateManualSettingsUserPick: (state, { payload }) => {
      state.draftRoom.manualLeagueSettings.userPick = payload
    },
    updateManualSettingsNumberOfRounds: (state, { payload }) => {
      state.draftRoom.manualLeagueSettings.rounds = payload
    },
    updateManualLeagueSettingsStarters: (
      state,
      { payload }: { payload: { position: DraftRoomSettingsPositions; value: number } }
    ) => {
      const { position, value } = payload
      state.draftRoom.manualLeagueSettings.starters[position] = value
    },
    updateManualLeagueSettingsDraftType: (state, { payload }) => {
      state.draftRoom.manualLeagueSettings.draftType = payload
      if (payload === DraftTypes.LINEAR) {
        state.draftRoom.leagueSettings.linear_drafting = 1
        state.draftRoom.leagueSettings.third_round_reversal = 0
      } else if (payload === DraftTypes.THIRD_ROUND_REVERSAL) {
        state.draftRoom.leagueSettings.third_round_reversal = 1
        state.draftRoom.leagueSettings.linear_drafting = 0
      } else {
        state.draftRoom.leagueSettings.linear_drafting = 0
        state.draftRoom.leagueSettings.third_round_reversal = 0
      }
    },
    updateManualLeagueSettingsScoringType: (state, { payload }) => {
      state.draftRoom.manualLeagueSettings.scoringType = payload
      let scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.PPR]
      if (payload === 'HALF PPR') {
        scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.ZERO_POINT_FIVE_PPR]
      } else if (payload === 'NON-PPR') {
        scoringType = FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.STANDARD]
      }
      state.filters.scoringType = scoringType
    },
    updateMdsEndingEarly: (state, { payload }) => {
      state.draftRoom.mdsEndingEarly = payload
    },
    updateDraftRoomEnded: (state, { payload }) => {
      state.draftRoom.draftEnded = payload
    },
    updateDraftRoomSettingsUserPick: (state, { payload }) => {
      state.draftRoom.leagueSettings.user_pick = payload
    },
    updateDraftRoomUserStrategyZeroRB: (state, { payload }) => {
      state.draftRoom.leagueSettings.user_strategy_zero_rb = payload
    },
    updateDraftRoomUserStrategyLateQB: (state, { payload }) => {
      state.draftRoom.leagueSettings.user_strategy_late_qb = payload
    },
    updateDraftRoomUserStrategyStacking: (state, { payload }) => {
      state.draftRoom.leagueSettings.user_strategy_stacking = payload
    },
    updateCPUTeamAffinityForLeagueSettings: (state, { payload }) => {
      const { index, selectedTeams } = payload
      state.draftRoom.leagueSettings.cpu_team_affinity[index] = selectedTeams
    },
    updateCPUTeamAversionForLeagueSettings: (state, { payload }) => {
      const { index, selectedTeams } = payload
      state.draftRoom.leagueSettings.cpu_team_aversion[index] = selectedTeams
    },
    updateCPUTeamAversionAndAffinitySize: (state, { payload }) => {
      state.draftRoom.leagueSettings.cpu_team_aversion = Array(payload).fill([])
      state.draftRoom.leagueSettings.cpu_team_affinity = Array(payload).fill([])
    },
    updateLiveDraftMode: (state, { payload }) => {
      state.draftRoom.liveDraftMode = payload
    },
    updateDraftRoomInitialLoad: (state, { payload }) => {
      state.draftRoom.initialLoad = payload
    },
    updatePlayerHasAnimated: (state, { payload }) => {
      state.draftRoom.draftPickAnimations[payload] = true
    },
    resetAllPlayerAnimations: (state) => {
      state.draftRoom.draftPickAnimations = {}
    },
    updateMutatingRankings: (state, { payload }) => {
      state.mutatingRankings = payload
    },
    updateDraftRoomShowDraftedPlayers: (state, { payload }) => {
      state.draftRoom.showDraftedPlayers = payload
    },
    updateLiveDraftStatus: (state, { payload }) => {
      state.draftRoom.liveDraftStatus = payload
    },
    updateLiveDraftHasContextualMessage: (state, { payload }) => {
      state.draftRoom.liveDraftHasContextualMessage = payload
    },
    updateLeagueDrafters: (state, { payload }: { payload: Drafter[] }) => {
      const sortedDrafters = [...payload].sort((a, b) => (a?.pickNumber || 0) - (b?.pickNumber || 0))
      state.draftRoom.leagueDrafters = sortedDrafters
    },
    updateSyncedLeagues: (state, { payload }: { payload: League[] }) => {
      state.syncedLeagues = [...payload].sort((league1, league2) => {
        const league1TimeStamp = new Date(league1.created_at).getTime()
        const league2TimeStamp = new Date(league2.created_at).getTime()
        return league2TimeStamp - league1TimeStamp
      })
    },
    updateLeagueKeepers: (state, { payload }: { payload: Keeper[] }) => {
      state.draftRoom.leagueKeepers = payload
      const teamName = state.draftRoom.selectedSyncedLeague?.team_name || ''
      const teamKeepers = getTeamKeepers(payload, teamName)
      state.draftRoom.teamKeepers = teamKeepers
    },
    updateDraftPickOrder: (state) => {
      const {
        rounds,
        user_pick: userPick,
        fantasy_players_in_league: fantasyPlayersInLeague,
      } = state.draftRoom.leagueSettings
      const drafters: Drafter[] = state.draftRoom.leagueDrafters
      const keepers = state.draftRoom.leagueKeepers

      let { draftType } = state.draftRoom.manualLeagueSettings
      if (state.draftRoom.selectedSyncedLeague) {
        draftType = state.draftRoom.selectedSyncedLeague.draft_type
      }
      const draftPicks = generateDraftPicksList(rounds, fantasyPlayersInLeague, userPick, draftType, drafters, keepers)

      const transformedObject: DraftPickOrder = draftPicks.reduce((acc: DraftPickOrder, item) => {
        const map = { ...acc }
        map[item.pick] = item.userTeamName
        return map
      }, {})
      state.draftRoom.draftPickOrder = transformedObject
    },
    updateInSeasonRosterPlayers: (state, { payload }: { payload: FantasyRoster }) => {
      state.inSeason.rosterPlayers = payload
    },
    setInSeasonMyTeamMatchupOpponent: (state, { payload }: { payload: MatchupPlayer | null }) => {
      state.inSeason.myTeam.rosterMatchup.opponent = payload
    },
    updateIsInSeasonSyncLeagueModalVisible: (state, { payload }: { payload: boolean }) => {
      state.inSeason.isSyncLeagueModalVisible = payload
    },
    updateInSeasonMatchupsSelectedPosition: (state, { payload }: { payload: PlayerPosition }) => {
      state.inSeason.matchups.selectedPosition = payload
    },
    setLeagueSyncFantasyProvider: (state, { payload }: { payload: FantasyProvider | null }) => {
      state.leagueSync.fantasyProvider = payload
    },
    setLeagueSyncActiveStep: (state, { payload }: { payload: number }) => {
      state.leagueSync.activeStep = payload
    },
    setLeagueSyncLeagueName: (state, { payload }: { payload: string | null }) => {
      state.leagueSync.leagueName = payload
    },
    setLeagueSyncLeagueUid: (state, { payload }: { payload: string | null }) => {
      state.leagueSync.uid = payload
    },
    updateRankingsActiveTab: (state, { payload }: { payload: number }) => {
      state.standaloneRankings.activeTab = payload
    },
  },
})
export const startMds = createAction('fantasy/mds/start')
export const startLda = createAction('fantasy/lda/start')
export const fetchRankings = createAction('fantasy/rankings/fetch')
export const setDraftStatusForProvidersWithExtension = createAction(
  'fantasy/lda/setDraftStatusForProvidersWithExtension',
  (payload: boolean) => {
    return { payload }
  }
)

export const mutateRankings = createAction('fantasy/rankings/mutate')
export const selectRecentlySyncedLeague = createAction('fantasy/in-season/synced-leagues/select')
export const processMatchupData = createAction('fantasy/in-season/synced-leagues/processMatchupData')
export const fantasyReducer = fantasySlice.reducer
export const fantasyActions = fantasySlice.actions

export const getFantasyState = (rootState: AppRootState): FantasyState => {
  return rootState[FANTASY_FILTER_KEY]
}

export const fantasySelectors = {
  getInSeasonWeeklyRankingsSelectedPosition: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.filters.position
  ),
  getInSeasonStartSitSelectedPosition: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.filters.position
  ),
  getInSeasonActiveTabIndex: createSelector(getFantasyState, (state: FantasyState) => state.inSeason.activeTabIndex),
  getSelectedPosition: createSelector(getFantasyState, (state: FantasyState) => state.filters.position),
  getSelectedLeagueType: createSelector(getFantasyState, (state: FantasyState) => state.filters.leagueType),
  getInSeasonWeeklyRankingsSelectedScoringType: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.filters.scoringType
  ),
  getInSeasonStartSitSelectedScoringType: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.filters.scoringType
  ),
  getSelectedScoringType: createSelector(getFantasyState, (state: FantasyState) => state.filters.scoringType),
  getInSeasonWeeklyRankingsSelectedTags: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.filters.tags
  ),
  getInSeasonStartSitSelectedTags: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.filters.tags
  ),
  getSelectedTags: createSelector(getFantasyState, (state: FantasyState) => state.filters.tags),

  getInSeasonWeeklyUnfilteredRankings: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.unfilteredRankings
  ),
  getUnfilteredRankings: createSelector(getFantasyState, (state: FantasyState) => state.unfilteredRankings),
  getInSeasonWeeklyRankings: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.rankings
  ),
  getInSeasonStartSitRankings: createSelector(getFantasyState, (state: FantasyState) => {
    const { rosterPlayers } = state.inSeason
    const roster = rosterPlayers.starters.concat(rosterPlayers.bench)
    const rosterPlayerIds = roster.map((player) => player.id).filter((id) => id)
    if (state.syncedLeagues.length > 0 && state.inSeason.selectedLeague && rosterPlayerIds.length > 0) {
      return state.inSeason.startSit.rankings.filter((ranking) => rosterPlayerIds.includes(ranking.id))
    }
    return state.inSeason.startSit.rankings
  }),
  getMutatedRankings: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.mutatedRankings),
  getRankings: createSelector(getFantasyState, (state: FantasyState) => state.rankings),
  getDraftRankingsUpdatedTime: createSelector(getFantasyState, (state: FantasyState) => state.draftRankingsUpdatedTime),
  getWeeklyRankingsUpdatedTime: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.updatedTime
  ),
  getRankerInfo: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.rankerInfo),
  getAllFilters: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.filters),
  getPrunedFilters: (listOfFilterstoBePruned: string[], type?: RankingsType) =>
    createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
      const filters = type === RankingsType.WEEKLY ? { ...state.inSeason.weeklyRankings.filters } : { ...state.filters }
      if (listOfFilterstoBePruned.length) {
        listOfFilterstoBePruned.forEach((filterToBePruned: string) => Reflect.deleteProperty(filters, filterToBePruned))
      }
      return filters
    }),
  getAllInSeasonWeeklyRankingsFilters: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.filters
  ),
  getAllInSeasonStartSitFilters: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.filters
  ),
  getInSeasonSelectedLeague: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.selectedLeague
  ),
  getIsSelectedInSeasonLeagueSuperflex: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.inSeason.selectedLeague) {
      const superflexPositions = state.inSeason.selectedLeague.league_settings?.op || 0
      return superflexPositions > 0
    }
    return true
  }),
  getSyncedLeagues: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    return state.syncedLeagues
  }),
  getNonManualSyncedLeagues: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const nonManualSyncedLeagues = state.syncedLeagues.filter((league) => league.hosted_site !== HostedSites.NONE)
    return nonManualSyncedLeagues
  }),
  getNonManualSyncedLeague: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const nonManualSyncedLeague =
      state.draftRoom.selectedSyncedLeague?.hosted_site !== HostedSites.NONE
        ? state.draftRoom.selectedSyncedLeague
        : null
    return nonManualSyncedLeague
  }),
  getInSeasonIsLeagueUnselected: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.isLeagueUnselected
  ),
  getInSeasonMyTeamMatchup: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.matchup
  ),
  getInSeasonMyTeamIsMatchupLoading: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.isMatchupLoading
  ),
  getInSeasonMyTeamIsOptimizeToggleOn: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.isOptimizeToggleOn
  ),
  getInSeasonMyTeamRosterMatchup: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.rosterMatchup.availableMatchup
  ),
  getInSeasonMyTeamSelectedMatchup: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.rosterMatchup.selectedMatchup
  ),
  getInSeasonMyTeamSelectedOpponent: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.rosterMatchup.opponent
  ),
  getInSeasonMyTeamPlayerGradeMap: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.myTeam.rosterMatchup.playerGradeMap
  ),
  getInSeasonMatchupsSelectedPosition: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.matchups.selectedPosition
  ),
  getSelectedPlayerToCompareWith: (playerId: number) =>
    createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
      const matchingId = state.playerCompare.selectedPlayerIds[playerId]
      if (!matchingId) {
        return {}
      }

      const match =
        state.unfilteredRankings.find((player: RankingsEntity) => player.id === matchingId) ||
        state.inSeason.weeklyRankings.unfilteredRankings.find(
          (player: WeeklyRankingsEntity) => player.id === matchingId
        )

      if (!match) {
        return {}
      }

      return {
        id: match.id,
        first_name: match.first_name,
        last_name: match.last_name,
        position: match.position,
        team: match.team,
      }
    }),
  getDraftedPlayerInfo: (playerIds: string[]) =>
    createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
      const { playerInfoMap } = state.draftRoom
      const playerInfoByIds: Record<string, Partial<DraftedPlayerInfo>> = {}
      playerIds.forEach((playerId) => {
        const player = playerInfoMap[playerId]
        if (player) {
          playerInfoByIds[playerId] = {
            id: player.id,
            firstName: player.firstName,
            lastName: player.lastName,
            team: player.team,
            teamCity: player.teamCity,
            teamName: player.teamName,
            position: player.position,
          }
        }
      })
      return playerInfoByIds
    }),

  getSelectedPlayerToCompareId: (playerId: number) =>
    createSelector(getFantasyState, (state: FantasyState) => state.playerCompare.selectedPlayerIds[playerId]),
  getInSeasonMyTeamPlayerRankings: (playerId: number) =>
    createSelector(getFantasyState, (state: FantasyState) => state.inSeason.myTeam.playerRankingsMap[playerId]),

  getPlayerCountFilteredByTags: (tags: string[]) =>
    createSelector(getFantasyState, (state: FantasyState) => {
      return state.unfilteredRankings.filter((player) => {
        return player.tags.some((tag) => tags.includes(tag))
      }).length
    }),
  getPlayerCountFilteredByTagsForStandaloneRankings: (tags: string[]) =>
    createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
      const { rankings, filters } = state
      const { search: searchTerm } = filters
      const playersFilteredByTags = getPlayersFilteredByTags(rankings, tags)
      const playersFilteredByName = getPlayersFilteredByName(playersFilteredByTags, searchTerm, RankingsType.DRAFT)
      return playersFilteredByName.length
    }),
  getSortedRankings: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const { rankings, filters, sort } = state
    const { sortBy, orderBy } = sort
    const { search: searchTerm, tags } = filters

    const playersFilteredByTags = getPlayersFilteredByTags(rankings, tags)
    const playersFilteredByName = getPlayersFilteredByName(playersFilteredByTags, searchTerm, RankingsType.DRAFT)
    return lodashOrderBy(playersFilteredByName.slice(), [sorters[sortBy]], [orderBy]) as RankingsEntity[]
  }),
  getSortedWeeklyRankings: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const { rankings: players, filters, sort } = state.inSeason.weeklyRankings
    const { sortBy, orderBy } = sort
    const { search: searchTerm } = filters

    const playersFiltered = getPlayersFilteredByName(players, searchTerm, RankingsType.WEEKLY)
    return lodashOrderBy(playersFiltered.slice(), [sorters[sortBy]], [orderBy]) as WeeklyRankingsEntity[]
  }),
  getSort: createSelector(getFantasyState, (state: FantasyState) => state.sort),
  getSearchTerm: createSelector(getFantasyState, (state: FantasyState) => state.filters.search),
  getInSeasonWeeklyRankingsSearchTerm: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.weeklyRankings.filters.search
  ),
  getInSeasonStartSitSearchTerm: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.filters.search
  ),
  getStartSitPlayerAId: createSelector(getFantasyState, (state: FantasyState) => state.inSeason.startSit.playerAId),
  getStartSitPlayerBId: createSelector(getFantasyState, (state: FantasyState) => state.inSeason.startSit.playerBId),
  getStartSitPlayerAPosition: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.playerAPosition
  ),
  getSelectedStartSitPlayerRankings: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const playerA = state.inSeason.startSit.rankings.find((player) => player.id === state.inSeason.startSit.playerAId)
    const playerB = state.inSeason.startSit.rankings.find((player) => player.id === state.inSeason.startSit.playerBId)

    return [playerA, playerB]
  }),
  hasValidStartSitSelection: createSelector(
    getFantasyState,
    (state: FantasyState) => state.inSeason.startSit.playerAId && state.inSeason.startSit.playerBId
  ),
  getCurrentGameWeek: createSelector(getFantasyState, (state: FantasyState) => state.inSeason.week),
  getFunFacts: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.inSeason.startSit.funFacts),
  getDraftPicks: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.draftRoom.draftPicks),
  getDraftSuggestions: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const draftRoomSuggestion: DraftRoomSuggestion = state.draftRoom?.suggestions
    const suggestionsWithPlayerInfo = draftRoomSuggestion?.suggestions?.map((suggestion: NextPickInfo) => {
      const id = suggestion.playerId
      const playerInfo = state.draftRoom.playerInfoMap[id]
      const nextRoundPlayers = suggestion.nextRoundPlayers.map((player: NextRoundInfo) => {
        const nextRoundId = player.playerId
        const nextRoundPlayerInfo = state.draftRoom.playerInfoMap[nextRoundId]
        return {
          ...player,
          ...nextRoundPlayerInfo,
        }
      })

      return {
        ...suggestion,
        ...playerInfo,
        nextRoundPlayers,
      }
    })

    return { ...draftRoomSuggestion, suggestions: suggestionsWithPlayerInfo } as SuggestionsWithPlayerInfo
  }),

  getDraftRoomSuggestedPlayerIds: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const draftRoomSuggestion: DraftRoomSuggestion = state.draftRoom?.suggestions
    if (draftRoomSuggestion?.suggestions?.length === 0) {
      return EMPTY_ARRAY
    }
    return draftRoomSuggestion.suggestions?.map((suggestion: NextPickInfo) => suggestion.playerId)
  }),
  getUserPicks: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.draftRoom.userPicks),
  getDraftRoomUserPickNumber: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.user_pick
  ),
  getPositionsAllowedToBeDrafted: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.positionsAllowedToBeDrafted
  ),
  getDraftRoomIsSuggestionsLoading: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.suggestionsLoading
  ),
  getDraftRoomIsDrafting: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.drafting),
  getPlayerPickGrades: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.playerPickGrades
  ),
  getPlayerNextRoundAvailability: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.playerNextRoundAvailability
  ),
  getSelectedSuggestedPlayerIndex: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.selectedSuggestedPlayerIndex
  ),
  getIsUserOnTheClock: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.userOnClock),
  getIsLiveDraftMode: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.liveDraftMode),
  getManualLeagueSettings: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.manualLeagueSettings
  ),
  getSyncedLeague: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.selectedSyncedLeague
  ),
  getSyncedLeagueProvider: createSelector(getFantasyState, (state: FantasyState) => {
    if (!state.draftRoom.selectedSyncedLeague) {
      return null
    }
    return state.draftRoom.selectedSyncedLeague.hosted_site
  }),
  getSyncedLeagueTeamName: createSelector(
    getFantasyState,
    (state: FantasyState) =>
      state.draftRoom.selectedSyncedLeague?.team_name || state.draftRoom.selectedSyncedLeague?.name || ''
  ),
  getDraftPickPlayers: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const {
      rounds,
      user_pick: userPick,
      fantasy_players_in_league: fantasyPlayersInLeague,
    } = state.draftRoom.leagueSettings
    const drafters: Drafter[] = state.draftRoom.leagueDrafters
    let { draftType } = state.draftRoom.manualLeagueSettings
    if (state.draftRoom.selectedSyncedLeague) {
      draftType = state.draftRoom.selectedSyncedLeague.draft_type
    }

    const keepers = state.draftRoom.leagueKeepers
    const draftPicks = generateDraftPicksList(rounds, fantasyPlayersInLeague, userPick, draftType, drafters, keepers)
    return transformDraftPicks(
      draftPicks,
      state.draftRoom.draftPicks,
      keepers,
      state.draftRoom.playerInfoMap,
      state.draftRoom.liveDraftMode,
      state.draftRoom.liveDraftPicks
    )
  }),
  getRoster: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const defaultPlayerInfo = {
      // Giving this as -9999 because, -1 is defined to be Bench position, that is yet to be filled. In this case, this is unknown player
      // who has been filled
      id: -9999,
      bye: -1,
      firstName: '',
      lastName: 'Unknown Player',
      position: 'BN',
      positionalRank: -1,
      team: '',
      teamCity: '',
      teamName: '',
    }

    // Filter Keepers from the user picks
    const filteredUserPicks = state.draftRoom.userPicks.filter(
      (id) => !state.draftRoom.leagueKeepers.some((player) => player.playerId === id)
    )

    const mappedUserPicks = filteredUserPicks.map((playerId) => {
      const playerInfo = state.draftRoom.playerInfoMap[playerId]

      return playerInfo || defaultPlayerInfo
    })

    // Only show users own keepers on roster view
    const userTeamName = state.draftRoom?.selectedSyncedLeague?.team_name
    const mappedKeepers = state.draftRoom.leagueKeepers
      .filter((keeper) => {
        return keeper.drafteeName === userTeamName
      })
      .map((keeper) => {
        const playerInfo = state.draftRoom.playerInfoMap[keeper.playerId]

        if (playerInfo) {
          return {
            ...playerInfo,
            isKeeper: true,
          }
        }
        return {
          ...defaultPlayerInfo,
          isKeeper: true,
        }
      })

    return mappedUserPicks.concat(mappedKeepers)
  }),
  getDraftRoomRounds: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.leagueSettings.rounds),
  getDraftRoomScoringType: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.draftRoom.selectedSyncedLeague) {
      const pprValue = state.draftRoom.selectedSyncedLeague.league_settings?.recv_receptions1
      return getScoringType(pprValue)
    }

    return state.draftRoom.manualLeagueSettings.scoringType
  }),
  getDraftType: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.draftRoom.selectedSyncedLeague) {
      return state.draftRoom.selectedSyncedLeague.draft_type
    }
    return state.draftRoom.manualLeagueSettings.draftType
  }),
  getDraftRoomStarterMap: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const startingLeagueSettings: Partial<LeagueSettingsStarters> = {}

    Object.entries(state.draftRoom.leagueSettings).forEach(([key, value]) => {
      if (key.startsWith('starting_')) {
        startingLeagueSettings[key as keyof LeagueSettingsStarters] = value
      }
    })

    return startingLeagueSettings as LeagueSettingsStarters
  }),
  getRosterStartersCount: createSelector(getFantasyState, (state: FantasyState) => {
    let startersCount = 0
    const leagueSettingsKeys = Object.keys(state.draftRoom.leagueSettings) as Array<keyof MDSLeagueSettings>
    const startingPlayerKeys = leagueSettingsKeys.filter((key) =>
      key.startsWith('starting_')
    ) as Array<LeagueSettingsStartersKeys>
    startingPlayerKeys.forEach((key) => {
      startersCount += state.draftRoom.leagueSettings[key] as number
    })
    return startersCount
  }),
  getFantasyPlayersInLeague: createSelector(
    getFantasyState,
    (state: FantasyState) =>
      state.draftRoom.selectedSyncedLeague?.size || state.draftRoom.leagueSettings.fantasy_players_in_league
  ),
  getIsLastDraftRound: createSelector(
    getFantasyState,
    (state: FantasyState) =>
      state.draftRoom.pickInfo &&
      state.draftRoom.leagueSettings &&
      state.draftRoom.pickInfo?.round >= state.draftRoom.leagueSettings?.rounds
  ),
  getCurrentRound: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.pickInfo?.round),
  getShouldShowLaggingSuggestionsBanner: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const currentSuggestionsRound = state.draftRoom.suggestions?.round
    const currentDraftRound = state.draftRoom.pickInfo?.round
    const { suggestionsLoading } = state.draftRoom
    const { suggestionsError } = state.draftRoom

    // Do not show banner if we have incomplete data or we are trying to fetch suggestions
    if (!currentSuggestionsRound || !currentDraftRound || (suggestionsLoading && !suggestionsError)) {
      return false
    }

    return Number(currentSuggestionsRound) < Number(currentDraftRound)
  }),
  getIsMockDraftEnded: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.draftEnded),
  getDraftRoomLeagueSettings: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings
  ),
  getManualLeagueSettingsNumberOfTeams: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.manualLeagueSettings.numberOfTeams
  ),

  getManualLeagueSettingsNumberOfRounds: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.manualLeagueSettings.rounds
  ),
  getManualLeagueSettingsStarters: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.manualLeagueSettings.starters
  ),
  getDraftRoomUserPick: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.draftRoom.selectedSyncedLeague !== null) {
      return state.draftRoom.leagueSettings.user_pick
    }
    // This is a manual league
    return state.draftRoom.manualLeagueSettings.userPick
  }),
  getMdsEndingEarly: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.mdsEndingEarly),
  getMdsResultsRequestBody: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    let suggestionScores = state.draftRoom.userPicksPickGrades
    if (state.draftRoom.liveDraftMode) {
      suggestionScores = new Array(state.draftRoom.leagueSettings.rounds).fill(0.5)
    }

    const updatedLeagueSettings = transformLeagueSettingsForMds(
      state.draftRoom.leagueSettings as MDSLeagueSettings,
      state.draftRoom.leagueKeepers
    )
    return {
      allPlayers: state.draftRoom.players,
      draftedPlayers: state.draftRoom.draftPicks,
      leagueSettings: updatedLeagueSettings,
      suggestionScores,
    } as GetMdsResultsRequest
  }),
  getIsDraftRoomUserStrategyLateQB: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.user_strategy_late_qb
  ),
  getIsDraftRoomUserStrategyStacking: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.user_strategy_stacking
  ),
  getIsDraftRoomUserStrategyZeroRB: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.user_strategy_zero_rb
  ),
  getDraftRoomUserStrategyLabel: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.draftRoom.leagueSettings.user_strategy_late_qb) {
      return DraftStrategyTypes.LATE_ROUND_QB
    }
    if (state.draftRoom.leagueSettings.user_strategy_stacking) {
      return DraftStrategyTypes.QB_WR_TE_STACKING
    }
    if (state.draftRoom.leagueSettings.user_strategy_zero_rb) {
      return DraftStrategyTypes.ZERO_RB
    }
    return ''
  }),
  getDraftRoomNumberOfTeams: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.draftRoom.selectedSyncedLeague) {
      return state.draftRoom.selectedSyncedLeague.size
    }

    return state.draftRoom.manualLeagueSettings.numberOfTeams
  }),
  getCPUTeamAffinityFromLeagueSettings: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.cpu_team_affinity
  ),
  getCPUTeamAversionFromLeagueSettings: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.cpu_team_aversion
  ),
  getDraftRoomInitialLoad: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.initialLoad),
  getIsPlayerDrafted: (playerId: number, position: FantasyPosition) =>
    createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
      const { draftPicks } = state.draftRoom
      return draftPicks.includes(convertToStringId(playerId, position === FantasyPosition.DST))
    }),
  getHasPlayerAnimatedIn: (playerId: number) =>
    createSelector(getFantasyState, (state: FantasyState) => {
      return state.draftRoom.draftPickAnimations[playerId]
    }),
  getDraftRoomIsInSuperflexMode: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.leagueSettings.starting_superflex > 0
  ),
  getIsMutatingRankings: createSelector(getFantasyState, (state: FantasyState) => state.mutatingRankings),
  getSpecificPlayerNextRoundAvailability: (playerId: number) =>
    createSelector(getFantasyState, (state: FantasyState) => {
      const suggestedPlayerSelectedIndex = state.draftRoom.selectedSuggestedPlayerIndex
      return state.draftRoom.playerNextRoundAvailability[playerId]?.[suggestedPlayerSelectedIndex] || 0
    }),
  getSpecificPlayerPickGrade: (playerId: number, position: FantasyPosition) =>
    createSelector(getFantasyState, (state: FantasyState) => {
      const convertedId = convertToStringId(playerId, position === FantasyPosition.DST)
      const corePickGradeValue = state.draftRoom.playerPickGrades[convertedId] || 0
      return round(corePickGradeValue * 100)
    }),
  getDraftRoomCanShowDraftedPlayers: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.showDraftedPlayers
  ),
  getLeagueKeeperExtractedIds: createDeepEqualSelector(getFantasyState, (state: FantasyState) =>
    state.draftRoom.leagueKeepers.map((keeper) => extractId(keeper.playerId))
  ),
  getLiveDraftPicks: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.draftRoom.liveDraftPicks),
  getLiveDraftStatus: createSelector(getFantasyState, (state: FantasyState) => state.draftRoom.liveDraftStatus),
  getLiveDraftHasContextualMessage: createSelector(getFantasyState, (state: FantasyState) => {
    const { liveDraftStatus, liveDraftPicks } = state.draftRoom
    let hasContextualMessage = true

    if (
      liveDraftStatus === LiveDraftStatus.DRAFTING ||
      (liveDraftStatus === '' && Object.keys(liveDraftPicks).length > 0)
    ) {
      hasContextualMessage = false
    }

    return hasContextualMessage
  }),
  getDraftRoomNumberOfPicks: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.draftPicks.length
  ),
  getLeagueDrafters: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.draftRoom.leagueDrafters),
  getDraftPickOrder: createDeepEqualSelector(getFantasyState, (state: FantasyState) => state.draftRoom.draftPickOrder),
  getLiveDraftPicksCount: createSelector(getFantasyState, (state: FantasyState) => {
    const latestLivePick = getLatestLiveDraftPickWithoutKeepers(
      state.draftRoom.liveDraftPicks,
      state.draftRoom.leagueKeepers
    )
    const livePicksCount = latestLivePick?.draftPick || 0

    return livePicksCount
  }),
  getLastDraftedPlayerPickTime: createSelector(getFantasyState, (state: FantasyState) => {
    const liveDraftPicksLength = Object.keys(state.draftRoom.liveDraftPicks).length
    const lastDraftedPlayerPickTime =
      state.draftRoom.liveDraftPicks[liveDraftPicksLength]?.pickedAt || new Date().toISOString()
    return lastDraftedPlayerPickTime
  }),
  getCurrentRoundPickNumber: createSelector(getFantasyState, (state: FantasyState) => {
    const numOfTeamsInTheLeague = state.draftRoom.leagueSettings.fantasy_players_in_league
    const { currentRoundPickNumber } = getCurrentLiveDraftRoundAndPickNumber(
      state.draftRoom.liveDraftPicks,
      state.draftRoom.leagueKeepers,
      numOfTeamsInTheLeague
    )

    return currentRoundPickNumber
  }),
  getLastPickInfo: createSelector(getFantasyState, (state: FantasyState) => {
    const lastPick = Object.keys(state.draftRoom.liveDraftPicks).length - 1
    return state.draftRoom.liveDraftPicks[lastPick]
  }),
  getCurrentPickPlayerName: createSelector(getFantasyState, (state: FantasyState) => {
    const numOfTeamsInTheLeague = state.draftRoom.leagueSettings.fantasy_players_in_league

    const { currentRoundPickNumber, liveDraftCurrentRound } = getCurrentLiveDraftRoundAndPickNumber(
      state.draftRoom.liveDraftPicks,
      state.draftRoom.leagueKeepers,
      numOfTeamsInTheLeague
    )
    const currentPlayerInPickOrder = (liveDraftCurrentRound - 1) * numOfTeamsInTheLeague + currentRoundPickNumber

    return state.draftRoom.draftPickOrder[currentPlayerInPickOrder]
  }),
  getNextPickPlayerName: createSelector(getFantasyState, (state: FantasyState) => {
    const numOfTeamsInTheLeague = state.draftRoom.leagueSettings.fantasy_players_in_league

    const { currentRoundPickNumber, liveDraftCurrentRound } = getCurrentLiveDraftRoundAndPickNumber(
      state.draftRoom.liveDraftPicks,
      state.draftRoom.leagueKeepers,
      numOfTeamsInTheLeague
    )
    const nextRoundPickNumber = currentRoundPickNumber + 1
    const nextPlayerInPickOrder = (liveDraftCurrentRound - 1) * numOfTeamsInTheLeague + nextRoundPickNumber

    return state.draftRoom.draftPickOrder[nextPlayerInPickOrder]
  }),
  getDraftDate: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.selectedSyncedLeague?.draft_date
  ),
  getDraftTimePerPick: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.selectedSyncedLeague?.time_per_pick
  ),
  getSyncedLeagueName: createSelector(
    getFantasyState,
    (state: FantasyState) => state.draftRoom.selectedSyncedLeague?.name
  ),
  getLiveDraftCurrentRound: createSelector(getFantasyState, (state: FantasyState) => {
    const numOfTeamsInTheLeague = state.draftRoom.leagueSettings.fantasy_players_in_league
    const { liveDraftCurrentRound } = getCurrentLiveDraftRoundAndPickNumber(
      state.draftRoom.liveDraftPicks,
      state.draftRoom.leagueKeepers,
      numOfTeamsInTheLeague
    )

    return liveDraftCurrentRound
  }),
  getDraftRoomLeagueIsYahoo: createSelector(getFantasyState, (state: FantasyState) => {
    return state.draftRoom.selectedSyncedLeague?.hosted_site === HostedSites.YAHOO
  }),
  getInSeasonRosterPlayers: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    return state.inSeason.rosterPlayers
  }),
  getInSeasonRosterPlayerIds: createDeepEqualSelector(getFantasyState, (state: FantasyState) => {
    const { rosterPlayers } = state.inSeason
    const roster = rosterPlayers.starters.concat(rosterPlayers.bench)
    // When a player in unavailable in PFF system the player id will be null in the api response, filter them out
    const rosterPlayerIds = roster.map((player) => player.id).filter((id) => id)
    return rosterPlayerIds
  }),
  getIsInSeasonSyncLeagueModalVisible: createSelector(getFantasyState, (state: FantasyState) => {
    return state.inSeason.isSyncLeagueModalVisible
  }),
  getLeagueSyncFantasyProvider: createSelector(getFantasyState, (state: FantasyState) => {
    return state.leagueSync.fantasyProvider
  }),
  getLeagueSyncActiveStep: createSelector(getFantasyState, (state: FantasyState) => {
    return state.leagueSync.activeStep
  }),
  getLeagueSyncLeagueName: createSelector(getFantasyState, (state: FantasyState) => {
    return state.leagueSync.leagueName
  }),
  getLeagueSyncUid: createSelector(getFantasyState, (state: FantasyState) => {
    return state.leagueSync.uid
  }),
  getSyncedFantasyLeagueAnalytics: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState): DraftAnalyticsInfo | null => {
      const syncedLeague = state.draftRoom.selectedSyncedLeague

      if (!syncedLeague) {
        return null
      }

      const transformedSettings = transformSyncedSettings(
        syncedLeague,
        state.draftRoom.leagueSettings as MDSLeagueSettings
      )

      const draftStrategyType = getDraftStrategy(
        transformedSettings.user_strategy_late_qb,
        transformedSettings.user_strategy_stacking,
        transformedSettings.user_strategy_zero_rb
      )
      const pprValue = syncedLeague.league_settings?.recv_receptions1
      const keepers = state.draftRoom.leagueKeepers
      const hasKeepers = keepers.length > 0

      return {
        platformName: syncedLeague ? syncedLeague.hosted_site : '',
        numberOfTeams: syncedLeague.size,
        yourDraftPickNumber: transformedSettings.user_pick,
        numberOfDraftRounds: transformedSettings.rounds,
        draftType: syncedLeague.draft_type,
        numberOfBenchSpots: transformedSettings.bench_spots,
        leagueScoringType: getScoringType(pprValue),
        draftStrategy: draftStrategyType,
        startingPositionCountQB: transformedSettings.starting_QB,
        startingPositionCountRB: transformedSettings.starting_RB,
        startingPositionCountWR: transformedSettings.starting_WR,
        startingPositionCountTE: transformedSettings.starting_TE,
        startingPositionCountFlex: transformedSettings.starting_flex,
        startingPositionCountSuperFlex: transformedSettings.starting_superflex,
        startingPositionCountDST: transformedSettings.starting_DST,
        startingPositionCountK: transformedSettings.starting_K,
        opponentStrategyFavoriteTeams: state.draftRoom.leagueSettings.cpu_team_affinity.some(
          (team) => team.length >= 1
        ),
        opponentStrategyAvoidedTeams: state.draftRoom.leagueSettings.cpu_team_aversion.some((team) => team.length >= 1),
        hasKeepers,
      }
    }
  ),
  getManualFantasyLeagueAnalytics: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState): DraftAnalyticsInfo | null => {
      const { manualLeagueSettings } = state.draftRoom

      if (!manualLeagueSettings) {
        return null
      }

      const transformedSettings = transformManualSettings(
        manualLeagueSettings,
        state.draftRoom.leagueSettings as MDSLeagueSettings
      )

      const draftStrategyType = getDraftStrategy(
        transformedSettings.user_strategy_late_qb,
        transformedSettings.user_strategy_stacking,
        transformedSettings.user_strategy_zero_rb
      )

      return {
        numberOfTeams: transformedSettings.fantasy_players_in_league,
        yourDraftPickNumber: transformedSettings.user_pick,
        numberOfDraftRounds: transformedSettings.rounds,
        draftType: manualLeagueSettings.draftType,
        numberOfBenchSpots: transformedSettings.bench_spots,
        leagueScoringType: state.draftRoom.manualLeagueSettings.scoringType,
        draftStrategy: draftStrategyType,
        startingPositionCountQB: transformedSettings.starting_QB,
        startingPositionCountRB: transformedSettings.starting_RB,
        startingPositionCountWR: transformedSettings.starting_WR,
        startingPositionCountTE: transformedSettings.starting_TE,
        startingPositionCountFlex: transformedSettings.starting_flex,
        startingPositionCountSuperFlex: transformedSettings.starting_superflex,
        startingPositionCountDST: transformedSettings.starting_DST,
        startingPositionCountK: transformedSettings.starting_K,
        opponentStrategyFavoriteTeams: state.draftRoom.leagueSettings.cpu_team_affinity.some(
          (team) => team.length >= 1
        ),
        opponentStrategyAvoidedTeams: state.draftRoom.leagueSettings.cpu_team_aversion.some((team) => team.length >= 1),
        hasKeepers: false,
      }
    }
  ),
  getLDAPlayerDraftedAnalytics: createDeepEqualSelector(
    getFantasyState,
    (state: FantasyState): LDAPlayerDraftedInfo => {
      const userPicks = [...state.draftRoom.userPicks]
      const latestDraftedPlayerId = getLatestDraftedPlayerId(userPicks)
      const suggestedPlayerIndex = findSuggestedPlayerIndex(
        state.draftRoom.suggestions.suggestions,
        latestDraftedPlayerId
      )
      const selectedSuggestedPlayer = state.draftRoom.suggestions.suggestions[suggestedPlayerIndex]
      const player = state.draftRoom.playerInfoMap[latestDraftedPlayerId]
      const totalDraftRounds = calculateRosterSize(state.draftRoom.selectedSyncedLeague?.league_settings)
      const numberId = extractId(latestDraftedPlayerId)
      const draftedPlayerPickGrade = calculateDraftGrade(
        state.draftRoom.playerPickGrades,
        latestDraftedPlayerId,
        selectedSuggestedPlayer
      )
      const availabilityPercent = getAvailabilityPercent(
        state.draftRoom.playerNextRoundAvailability,
        numberId,
        selectedSuggestedPlayer
      )

      const isDST = player?.position === FantasyPosition.DST

      return {
        experience: FantasyExperiences.LDA,
        platformName: state.draftRoom.selectedSyncedLeague ? state.draftRoom.selectedSyncedLeague.hosted_site : 'null',
        player_rank: player?.positionalRank || 0,
        drafted_player_pick_grade: draftedPlayerPickGrade,
        availabilityPercent,
        draft_round: state.draftRoom.pickInfo?.round,
        numberOfDraftRounds: totalDraftRounds,
        playerPosition: player?.position,
        player_suggested: suggestedPlayerIndex === -1 ? 'null' : suggestedPlayerIndex + 1,
        player_name: isDST
          ? getDstName(player?.teamCity, player?.teamName, false)
          : `${player?.firstName} ${player?.lastName}`,
      }
    }
  ),
  getLDARoundStartedAnalytics: createDeepEqualSelector(getFantasyState, (state: FantasyState): LDARoundStartedInfo => {
    const totalDraftRounds = calculateRosterSize(state.draftRoom.selectedSyncedLeague?.league_settings)
    const currentRound = state.draftRoom.pickInfo?.round
    const roundCompletionRate = (currentRound / totalDraftRounds) * 100

    return {
      platformName: state.draftRoom.selectedSyncedLeague ? state.draftRoom.selectedSyncedLeague.hosted_site : 'null',
      draft_round: currentRound,
      numberOfDraftRounds: totalDraftRounds,
      roundCompletionRate,
    }
  }),
  getIsDraftRankingsTabSelected: createSelector(getFantasyState, (state: FantasyState) => {
    return state.standaloneRankings.activeTab === 0
  }),
  getIsIdpScoringTypeSelected: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.standaloneRankings.activeTab === 0) {
      return state.filters.scoringType.includes(FantasyScoringType.IDP)
    }
    return state.inSeason.weeklyRankings.filters.scoringType.includes(
      FANTASY_CONSTANTS.API_SCORING_TYPE[FantasyScoringType.IDP]
    )
  }),
  getIsWeeklyRankingsAvailable: createSelector(getFantasyState, (state: FantasyState) => {
    if (state.inSeason.weeklyRankings.rankings.length > 0) {
      return true
    }

    return false
  }),
}
