import { StartTimestamp } from '../common'
import { ForecastDetails } from '../common/forecast'
import { StadiumDetails } from '../common/stadium'
import { FootballLeague } from './football-league'
import { FootballTeamV2 } from './football-team'

export type FootballEventStatus =
  | 'Scheduled'
  | 'InProgress'
  | 'Final'
  | 'F/OT'
  | 'Suspended'
  | 'Postponed'
  | 'Delayed'
  | 'Canceled'
  | 'Forfeit'
  | 'DataReview'

export interface FootballGameBoxScore {
  awayScoreQuarter1: number
  awayScoreQuarter2: number
  awayScoreQuarter3: number
  awayScoreQuarter4: number
  awayScoreOvertime: number
  homeScoreQuarter1: number
  homeScoreQuarter2: number
  homeScoreQuarter3: number
  homeScoreQuarter4: number
  homeScoreOvertime: number
}

export interface LiveFootballGame extends Partial<FootballGameBoxScore> {
  homeScore: number
  awayScore: number
  homeTimeouts: number
  awayTimeouts: number
  quarter: string
  timeRemaining: string
}

export interface FootballGame extends Partial<LiveFootballGame>, StartTimestamp {
  league: FootballLeague
  gameId: number
  season: number
  awayTeamId: number
  homeTeamId: number
  gameStatus: FootballEventStatus
  channel?: string | null
  stadiumDetails?: StadiumDetails
  forecastDetails?: ForecastDetails
}

export const FootballGameStatus = {
  CANCELED: 'canceled',
  UPCOMING: 'upcoming',
  COMPLETE: 'complete',
  IN_PROGRESS: 'in-progress',
  FINAL_OVERTIME: 'final-overtime',
}

export interface FootballGameScoreDetails {
  home: number | null
  away: number | null
  homeTimeouts: number | null
  awayTimeouts: number | null
  gameClock: string | null
  quarter: string | null
  createdAt: string
  updatedAt: string
}

export interface FootballGameV2 {
  id: number
  season: number
  week: number
  league: string
  startTime: number | null
  network: string | null
  status: FootballEventStatus | null
  homeTeam: FootballTeamV2
  awayTeam: FootballTeamV2
  scoreDetails: FootballGameScoreDetails
  stadiumDetails?: StadiumDetails
  forecastDetails?: ForecastDetails
  createdAt: string
  updatedAt: string
}
