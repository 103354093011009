import { createContext } from 'react'

interface FantasyContextInterface {
  isWeb: boolean
  isLiveDraftPage?: boolean
  isDraftPage?: boolean
  isStandaloneRankings?: boolean
  screenWidth?: number
  screenHeight?: number
  isPremiumSubscriber?: boolean
  isLoggedIn?: boolean
  isExpiredUser?: boolean
  isOnStandaloneCheatsheetPage?: boolean
}

/**
 * isWeb - Flag to switch web and native theme in feature-fantasy library
 * isLiveDraftPage - Live draft assistant page
 * isDraftPage - Mock draft simulator / Live draft assistant page
 */
const defaultProviderValue = {
  isWeb: false,
  isLiveDraftPage: false,
  isDraftPage: false,
  isStandaloneRankings: false,
  screenWidth: undefined,
  screenHeight: undefined,
  isPremiumSubscriber: false,
  isLoggedIn: false,
  isExpiredUser: false,
  isOnStandaloneCheatsheetPage: false,
}

export const FantasyContext = createContext<FantasyContextInterface>(defaultProviderValue)
