import type { DraftedPlayerInfo, LeagueSettingsStarters } from '@pff-consumer/schema'

type LeagueStarterPositions = {
  [position: string]: number
}

type DraftedPositionsCount = {
  [position: string]: number
}

type PositionsMap = {
  [key: string]: string[]
}

const positionsMap: PositionsMap = {
  QB: ['QB'],
  WR: ['WR'],
  RB: ['RB'],
  TE: ['TE'],
  K: ['K'],
  DST: ['DST'],
  FLX: ['WR', 'RB', 'TE'],
  SFLX: ['WR', 'RB', 'TE', 'QB'],
  BN: ['QB', 'WR', 'RB', 'TE', 'K', 'DST'],
}

export const getPositionsThatCanBeDrafted = (
  userPicks: DraftedPlayerInfo[],
  leagueSettingsStarters: LeagueSettingsStarters,
  benchCount: number
): string[] => {
  const BENCH_POSITION: string = 'BN'
  const FLEX_POSITION: string = 'FLX'
  const SUPERFLEX_POSITION: string = 'SFLX'
  const FLEX_STARTER_POSITION_TEXT: string = 'flex'
  const SUPERFLEX_STARTER_POSITION_TEXT: string = 'superflex'
  const PREFIX_CHARS_TO_BE_REMOVED: number = 9

  const filledPositions: DraftedPositionsCount = {}

  const startingPositions: LeagueStarterPositions = Object.entries(leagueSettingsStarters).reduce(
    (acc, [key, value]) => {
      // Slicing 9 because, we need to remove the prefix "starting_" that comes from League settings
      let positionKey = key.slice(PREFIX_CHARS_TO_BE_REMOVED)
      if (positionKey === FLEX_STARTER_POSITION_TEXT) positionKey = FLEX_POSITION
      if (positionKey === SUPERFLEX_STARTER_POSITION_TEXT) positionKey = SUPERFLEX_POSITION
      return { ...acc, [positionKey]: value }
    },
    { BN: benchCount }
  )

  // Helper function to check if a position is already filled
  const canPositionBeFilled = (position: string): boolean => {
    const positionFilledStatus =
      startingPositions[position] > 0 &&
      (!filledPositions[position] || filledPositions[position] < startingPositions[position])
    return positionFilledStatus
  }

  const canFlexPositionBeFilled = (position: string): boolean => {
    // Try to fill the FLX position with WR, RB, or TE if available
    const flxOptions = ['WR', 'RB', 'TE']
    return flxOptions.includes(position) && canPositionBeFilled(FLEX_POSITION)
  }

  const canSuperFlexPositionBeFilled = (position: string): boolean => {
    // Try to fill the SFLX position with WR, RB,TE or QB if available
    const sflxOptions = ['WR', 'RB', 'TE', 'QB']
    return sflxOptions.includes(position) && canPositionBeFilled(SUPERFLEX_POSITION)
  }

  // Iterate through the players and assign them to their respective positions
  userPicks.forEach((player) => {
    const position = player?.position
    if (!position) {
      return
    }

    if (canPositionBeFilled(position)) {
      filledPositions[position] = filledPositions[position] + 1 || 1
    } else if (canFlexPositionBeFilled(position)) {
      // If the position is already filled, check to fill FLX position
      filledPositions[FLEX_POSITION] = filledPositions[FLEX_POSITION] + 1 || 1
    } else if (canSuperFlexPositionBeFilled(position)) {
      // If the positions & FLX are already filled, check to fill SFLX position
      filledPositions[SUPERFLEX_POSITION] = filledPositions[SUPERFLEX_POSITION] + 1 || 1
    } else {
      // If all positions, Flex and SuperFlex are filled, fill the player at the BN position of the roster
      filledPositions[BENCH_POSITION] = filledPositions[BENCH_POSITION] + 1 || 1
    }
  })

  const possiblePositions: string[] = []

  Object.entries(positionsMap).forEach(([key, positions]) => {
    if (canPositionBeFilled(key)) {
      possiblePositions.push(...positions)
    }
  })

  return [...new Set(possiblePositions)]
}
