interface Links {
  width: number
  height: number
  href: string
}

interface EntityIds {
  origin: string
  id: string
  sport: string
  alt_id?: string
}

interface Refs {
  name: string
  type: string
  sport: string
  sportsdata_id: string
  sportradar_id: string
  primary?: boolean
  entity_ids: EntityIds[]
}

interface Provider {
  name: string
  provider_item_id: string
  original_publish: string
}

interface SportradarAssetList {
  id: string
  created: string
  updated: string
  title: string
  description: string
  player_id: string
  copyright: string
  'posed=': boolean
  links: Links[]
  refs: Refs[]
  provider: Provider[]
}

export interface SportradarManifestData {
  provider: string
  league: string
  type: string
  manifest_date: string
  trial: boolean
  assetlist: SportradarAssetList[]
}

export interface PlayerExternalIdentifier {
  player_id: number
  gsis_id: string
  sportradar_id: string
  college_gsis_id: string
  gsis_alt_id: string
  my_fantasy_league_id: string | null
  nfl_id: string | null
  stats_global_id: string | null
  stats_id: string | null
}

export interface PlayerExternalIdentifiersResponse {
  player_external_identifiers: PlayerExternalIdentifier[]
}

export enum EndpointPaths {
  PREMIUM_PATH = '/nfl-images-p3/ap_premium',
  PLAYER_EXTERNAL_IDENTIFIERS = '/api/v1/player_external_identifiers',
}
